import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ModalX from "../../../assets/modal-x.svg";
import UploadIcon from "../../../assets/upload_icon.svg";
import camera from "../../../assets/Vector.png";
import './Modal.css';
import { toast } from 'react-toastify';
import { createReelApi } from '../../../redux/api/user';


const PlayerCreatReelModal = ({ showModal, closeModal }) => {
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [keywords, setKeywords] = useState([]);
    const [currentKeyword, setCurrentKeyword] = useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setVideoUrl(URL.createObjectURL(selectedFile));
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && currentKeyword) {
            e.preventDefault();
            setKeywords([...keywords, currentKeyword]);
            setCurrentKeyword('');
        }
    };

    const handlePostVideo = () => {
        if (!title || keywords.length === 0 || !file) {
            toast.warn('Please fill all fields and upload a file.');
            return;
        }

        const loginData = localStorage.getItem('loginData');
        let user = {};
        if (loginData && loginData !== 'undefined') {
            try {
                user = JSON.parse(loginData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }

        const userToken = user.token;
        const userId = user._id;

        if (user && userToken) {
            setLoading(true);
            const formData = new FormData();
            formData.append('video', file);
            formData.append('title', title);
            formData.append('keywords', JSON.stringify(keywords));

            createReelApi({userId: userId,token: userToken,formData: formData,
                 onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted); // This updates the progress percentage in the frontend
                }
            })
                .then((response) => {
                    setLoading(false);
                    setUploadProgress(0);
                    toast.success('Video uploaded successfully!');
                    handleModalClose();
                })
                .catch((error) => {
                    setLoading(false);
                    setUploadProgress(0);
                    console.error('Error creating reel:', error);
                    toast.error('Failed to upload video.');
                });
        } else {
            toast.error('User not found or token is missing.');
        }
    };

    const handleModalClose = () => {
        closeModal();
        setTitle('');
        setKeywords([]);
        setFile(null);
        setVideoUrl('');
        setLoading(false);
        setUploadProgress(0);
    };

    return (
        <div className="modal_for_video_reels">
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header>
                    <div className="tab_on_topp">
                        <h6>Create Reel</h6>
                    </div>
                    <span className='modal_close_cross' onClick={handleModalClose}>
                        <img className='cursor' src={ModalX} alt="cross-icon" />
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal_body_form_wrap">
                        <form>
                            <div className="form_wrap">
                                <label htmlFor="title">Title</label>
                                <textarea
                                    className="textArea"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>

                            <div className="form_wrap">
                                <label>Keywords</label>
                                <div className="tagInput">
                                    {keywords.map((item, index) => (
                                        <button key={index}>
                                            {item}
                                        </button>
                                    ))}
                                    <input
                                        className="inputtag"
                                        type="text"
                                        value={currentKeyword}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => setCurrentKeyword(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="upload" className="text">Media</label>
                                <div className="form-uploadd">
                                    <div className="custom-file-input" style={{ opacity: '1' }}>
                                        <label htmlFor="file-upload-camera">
                                            <input
                                                id="file-upload-camera"
                                                type="file"
                                                accept="image/*"
                                            />
                                            <span>
                                                <img src={camera} alt="Camera Icon" className="icon" />
                                            </span>
                                            <span className="text">Camera</span>
                                        </label>
                                    </div>
                                    <div className="custom-file-input" style={{ opacity: '1' }}>
                                        <label htmlFor="video-upload">
                                            <input
                                                id="video-upload"
                                                type="file"
                                                accept="video/*"
                                                onChange={handleFileChange}
                                            />
                                            <span>
                                                <img src={UploadIcon} alt="Upload Icon" className="icon" />
                                            </span>
                                            <span className="text">Upload</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {videoUrl && (
                                <div className="after_upload_section">
                                    <label htmlFor="upload" className="text">Media</label>
                                    <div className="after_upload_video d-flex">
                                        <div className="after_upload_left">
                                            <div className="video-container">
                                                <video width="320" height="240" controls>
                                                    <source src={videoUrl} type={file?.type} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                {loading && (
                                                    <div className="loader-overlay">
                                                        <div className="loader"></div>
                                                        <p>Uploading: {uploadProgress}%</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="after_upload_right">
                                            <h5>{file ? file.name : 'No file selected'}</h5>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="post_video_btn text-center">
                                <Button type="button" onClick={handlePostVideo} disabled={loading}>
                                    {loading ? 'Uploading...' : 'Post Video'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PlayerCreatReelModal;
