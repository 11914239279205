import React, { useState, useEffect } from 'react';
import './Footer.css';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

function Footer({  onPlayPause, onMuteUnmute, muted, progress }) {
    const [volumeIcon, setVolumeIcon] = useState(muted ? faVolumeMute : faVolumeUp);

    useEffect(() => {
        setVolumeIcon(muted ? faVolumeMute : faVolumeUp);
    }, [muted]);

    return (
        <div className="videoFooter">
            <div className="controls">
                <button onClick={onPlayPause} className="control-button">
                    {/* <FontAwesomeIcon icon={playing ? faPause : faPlay} /> */}
                </button>
                <button 
                    onClick={onMuteUnmute} 
                    className="control-button"
                >
                    {/* <FontAwesomeIcon icon={volumeIcon} /> */}
                </button>
            </div>
            <div className="progress-container">
                <div 
                    className="progress-bar"
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
        </div>
    );
}

export default Footer;
