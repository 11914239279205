import React from "react";
import ImageFront from "../../../../assets/Front.svg";
import ImageLeft from "../../../../assets/left.svg";
import ImageRight from "../../../../assets/Right.svg";
import ImageUp from "../../../../assets/Up.svg";
import ImageDown from "../../../../assets/Down.svg";

const imageSources = {
  frontImage: ImageFront,
  leftImage: ImageLeft,
  rightImage: ImageRight,
  upImage: ImageUp,
  downImage: ImageDown,
};

const UploadPictures = ({ setFieldValue }) => {
  const handleImageClick = (field) => {
    document.getElementById(field).click();
  };

  const handleFileChange = (event, field) => {
    const file = event.currentTarget.files[0];
    setFieldValue(field, file);
  };

  return (
    <>
      <div className="container">
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <h2 className="progressbar-below-form">
            Now for the last part upload your pictures?
          </h2>
        </div>
        <div className="images_upload_row_wrap flex-wrap d-flex gap-5">
          {Object.keys(imageSources).map((field) => (
            <React.Fragment key={field}>
              <img
                src={imageSources[field]}
                alt={`Upload ${field}`}
                onClick={() => handleImageClick(field)}
              />
              <input
                type="file"
                id={field}
                style={{ display: "none" }}
                accept="image/*"
                capture="environment" // Use "user" for the front camera
                onChange={(event) => handleFileChange(event, field)}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default UploadPictures;
