import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ModalX from '../../../assets/modal-x.svg';
import { useFormik } from 'formik';
import './Modal.css';
import logo from '../../../assets/Group 1000003821.png';

const AddplayerModal = ({ showModal, closeModal }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility

  // Validate date of birth input
  // const validateDateOfBirth = (values) => {
  //   const { day, month, year } = values.dob;
  //   let errors = {};
    
  //   if (!day || day < 1 || day > 31) {
  //     errors.dob = 'Invalid Day';
  //   }
  //   if (!month || month < 1 || month > 12) {
  //     errors.dob = 'Invalid Month';
  //   }
  //   if (!year || year.length !== 4 || year < 1900 || year > new Date().getFullYear()) {
  //     errors.dob = 'Invalid Year';
  //   }
  //   return errors;
  // };

  // const validatePhoneNumber = (value) => {
  //   const phoneRegex = /^[0-9]{10}$/; // Regular expression for a 10-digit phone number
  //   if (!phoneRegex.test(value)) {
  //     return 'Invalid Phone Number';
  //   }
  //   return null;
  // };

  // Formik setup for form handling
  const formik = useFormik({
    initialValues: {
      Fname: '',
      Lname: '',
      dob: { day: '', month: '', year: '' },
      gender: '',
      number: '',
      email: '',
      tshirtNumber: '',
      position: '',
    },
    validate: (values) => {
      let errors = {};

      // Validate phone number
      // const phoneError = validatePhoneNumber(values.number);
      // if (phoneError) errors.number = phoneError;

      // Validate DOB
      // const dobErrors = validateDateOfBirth(values);
      // if (dobErrors.dob) errors.dob = dobErrors.dob;

      return errors;
    },
    onSubmit: (values) => {
      console.log('Form Submitted', values);
    },
  });

  // Handle gender selection
  const handleGenderChange = (gender) => {
    formik.setFieldValue('gender', gender);
    setDropdownOpen(false);
  };

  // Close modal handler
  const handleModalClose = () => {
    closeModal();
  };

  return (
    <div className="modal_for_video_reels">
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header>
          <div className="tab_on_topp">
            <h6>Add Player</h6>
          </div>
          <span className="modal_close_cross" onClick={handleModalClose}>
            <img className="cursor" src={ModalX} alt="cross-icon" />
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-4 d-flex justify-content-center align-items-center">
            <img src={logo} alt="Logo" />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="mb-3 col-sm-6">
                <label htmlFor="Fname" className="form-label text-white">
                  First Name
                </label>
                <input
                  type="text"
                  id="Fname"
                  name="Fname"
                  className="form-control custom-input"
                  placeholder="First Name"
                  value={formik.values.Fname}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mb-3 col-sm-6">
                <label htmlFor="Lname" className="form-label text-white">
                  Last Name
                </label>
                <input
                  type="text"
                  id="Lname"
                  name="Lname"
                  className="form-control custom-input"
                  placeholder="Last Name"
                  value={formik.values.Lname}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-sm-6">
                <label className="form-label text-white">Date of Birth</label>
                <div className="row">
                  <div className="col-4">
                    <input
                      type="text"
                      className=
                      // {`
                        "form-control custom-input"
                        //  ${formik.errors.dob ? 'is-invalid' : ''}`}
                      name="dob.day"
                      placeholder="DD"
                      value={formik.values.dob.day}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      className={`form-control custom-input ${formik.errors.dob ? 'is-invalid' : ''}`}
                      name="dob.month"
                      placeholder="MM"
                      value={formik.values.dob.month}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-4">
                    <input
                      type="text"
                      className={`form-control custom-input ${formik.errors.dob ? 'is-invalid' : ''}`}
                      name="dob.year"
                      placeholder="YYYY"
                      value={formik.values.dob.year}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                {/* {formik.errors.dob && <div className="text-danger">{formik.errors.dob}</div>} */}
              </div>
              <div className="mb-3 col-sm-6">
                <label className="form-label text-white">Gender</label>
                <div className="input-group custom-input-group">
                  <input
                    type="text"
                    className="custom-input form-control"
                    name="gender"
                    value={formik.values.gender || 'Select Gender'}
                    readOnly
                  />
                  <button
                    className="btn btn-outline-secondary dropdown-toggle bg-282828"
                    type="button"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  ></button>
                  {dropdownOpen && (
                    <ul className="dropdown-menu dropdown-menu-end show w-100">
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleGenderChange('Male')}
                        >
                          Male
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleGenderChange('Female')}
                        >
                          Female
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => handleGenderChange('Other')}
                        >
                          Other
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-sm-6">
                <label className="form-label text-white">Phone Number</label>
                <input
                  type="text"
                  className={`form-control custom-input ${formik.errors.number ? 'is-invalid' : ''}`}
                  value={formik.values.number}
                  onChange={formik.handleChange}
                  name="number"
                />
                {/* {formik.errors.number && <div className="text-danger">{formik.errors.number}</div>} */}
              </div>
              <div className="mb-3 col-sm-6">
                <label className="form-label text-white">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control custom-input"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-sm-6">
                <label htmlFor="tshirtNumber" className="form-label text-white">
                  T-shirt Number
                </label>
                <input
                  type="text"
                  id="tshirtNumber"
                  name="tshirtNumber"
                  className="form-control custom-input"
                  placeholder="T-shirt Number"
                  value={formik.values.tshirtNumber}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="mb-3 col-sm-6">
                <label htmlFor="position" className="form-label text-white">
                  Position
                </label>
                <input
                  type="text"
                  id="position"
                  name="position"
                  className="form-control custom-input"
                  placeholder="Position"
                  value={formik.values.position}
                  onChange={formik.handleChange}
                />
              </div>
            </div>

            <div className="Change-Password-btn row justify-content-center">
              <div className="col-auto">
                <button type="submit">Add</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddplayerModal;
