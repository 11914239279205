import React from 'react'
import './Profile.css';
import ReelBanner from '../../assets/Rectangle 4217.png'
import profile from '../../assets/Ellipse 192.png'
import profile1 from '../../assets/Ellipse 193.png'
import profile2 from '../../assets/Ellipse 194.png'
import profile3 from '../../assets/Ellipse 195.png'

const Reeldetail = () => {



  return (
    <div>
      <div className="row">
        <div className="col-6 reeldetail-img">
          <img src={ReelBanner} alt="" />
        </div>
        <div className="col-6 ">
          <div className="reeldetail-details">
            <div className='reeldetail-header'><span>SFG vs ECSB match _ 20-12-2023 </span></div>
            <div className="row m-2">
              <div className="col-5 reeldetail-div mr-3 ">
                <div className="d-flex justify-content-between align-items-center m-1 t-text">
                  <span >Total Views</span>
                  <p className="mb-0"><i className="fa-regular fa-circle-question"></i></p>
                </div>
                <div className="text-center mt-3 t-view-text ">
                  <span>500</span>
                </div>
              </div>

              <div className="col-5 reeldetail-div ml-5">
                <div className="d-flex justify-content-between align-items-center m-1 t-text">
                  <span >Share Count</span>
                  <p className="mb-0"><i className="fa-regular fa-circle-question"></i></p>
                </div>
                <div className="text-center mt-3 t-view-text ">
                  <span>162</span>
                </div>
              </div>

            </div>


            <div className="row m-2 mt-4">
              <div className="col-5 reeldetail-div  mr-3">
                <div className="d-flex justify-content-between align-items-center m-1 t-text">
                  <span >Likes</span>
                  <p className="mb-0"><i className="fa-regular fa-circle-question"></i></p>
                </div>
                <div className="text-center mt-3 t-view-text ">
                  <span>200</span>
                </div>
              </div>

              <div className="col-5 reeldetail-div ml-5">
                <div className="d-flex justify-content-between align-items-center m-1 t-text">
                  <span >Saved in Playlist</span>
                  <p className="mb-0"><i className="fa-regular fa-circle-question"></i></p>
                </div>
                <div className="text-center mt-3 t-view-text ">
                  <span>20</span>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div className="Audience">
        <div className='audience-pol'><span>Audience Engagement</span></div>
        <div>
        <div className="row">
        <div className="col-12">
  <table className="all-table form-bg p-4">
    <thead className='table-header-div '>
      <tr>
        <th>User name</th>
        <th>Profile type</th>
        <th>Subscription</th>
        <th>No. Views</th>
        <th>Like</th>
        <th>Dislike</th>
        <th>Shared</th>
      </tr>
    </thead>
    <tbody className="table-body">
      <tr>
        <td><img src={profile} alt="" className="profile-img" /> <span className='ml-2'>Marcus Vaccaro</span></td>
        <td><button className='butn1'>Technical</button></td>
        <td>Basic</td>
        <td>10</td>
        <td>01</td>
        <td>00</td>
        <td>01</td>
      </tr>
      <tr>
        <td><img src={profile1} alt="" className="profile-img" /> <span className='ml-2'>Jessica White</span></td>
        <td><button className='butn2'>Personal</button></td>
        <td>Premium</td>
        <td>50</td>
        <td>30</td>
        <td>02</td>
        <td>10</td>
      </tr>
      <tr>
        <td><img src={profile2} alt="" className="profile-img" /> <span className='ml-2'>John Doe</span></td>
        <td><button className='butn2'>Personal</button></td>
        <td>Standard</td>
        <td>20</td>
        <td>10</td>
        <td>01</td>
        <td>05</td>
      </tr>
      <tr>
        <td><img src={profile3} alt="" className="profile-img" /> <span className='ml-2'>John Doe</span></td>
        <td><button className='butn1'>Developer</button></td>
        <td>Standard</td>
        <td>20</td>
        <td>10</td>
        <td>01</td>
        <td>05</td>
      </tr>
      <tr>
        <td><img src={profile2} alt="" className="profile-img" /> <span className='ml-2'>John Doe</span></td>
        <td><button className='butn2'>Personal</button></td>
        <td>Standard</td>
        <td>20</td>
        <td>10</td>
        <td>01</td>
        <td>05</td>
      </tr>
      <tr>
        <td><img src={profile3} alt="" className="profile-img" /> <span className='ml-2'>John Doe</span></td>
        <td><button className='butn1'>Developer</button></td>
        <td>Standard</td>
        <td>20</td>
        <td>10</td>
        <td>01</td>
        <td>05</td>
      </tr>
    </tbody>
  </table>
</div>

</div>


        </div>

      </div>
    </div>
  )
}

export default Reeldetail