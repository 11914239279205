import React, { useState } from "react";
import Brand from "../../assets/Frame 58938.png";
import DotsA from "../../assets/dots-a.svg";
import DotsB from "../../assets/dots-b.svg";
import ReliabilityIcon from "../../assets/reliability.svg";
import CompetetiveIcon from "../../assets/competetive.svg";
import TimesavingIcon from "../../assets/timesaving.svg";
import ArrowRight from "../../assets/arrow_right.svg";
import ProspectesA from "../../assets/prospectes_a.svg";
import ProspectesB from "../../assets/prospectes_b.svg";
import ProspectesC from "../../assets/prospectes_c.svg";
import backgroundImage from "../../assets/background.svg";
import HomeIcon from "../../assets/home-icon.svg";
import MessageIcon from "../../assets/message-icon.svg";
import PhoneIcon from "../../assets/phone-icon.svg";
import PrintIcon from "../../assets/print-icon.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import TwitterIcon from "../../assets/twitter-icon.svg";
import BrowserIcon from "../../assets/browser-icon.svg";
import LinkedIn from "../../assets/linkedin-icon.svg";
import GreenCheck from "../../assets/green_check.svg";
import RedCross from "../../assets/red-cross.svg";

import "./landing.css";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  // const history = useHistory();
  const navigate =useNavigate()

  // For Background Image
  const divStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  // For Tabs
  const [activeTab, setActiveTab] = useState("about");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleClick = () => {
    navigate('/Login');
  };

  return (
    <div>
      <div className="header_fixed">
        {/* LANDING HEADER */}
        <div className="custom_header_landing">
          <div className="left_logo">
            <img src={Brand} alt="main_logo" />
          </div>
          <div className="center_items">
            <ul>
              <li
                className={activeTab === "about" ? "active" : ""}
                onClick={() => handleTabClick("about")}
              >
                About
              </li>
              <li
                className={activeTab === "pricing" ? "active" : ""}
                onClick={() => handleTabClick("pricing")}
              >
                Pricing
              </li>
            </ul>
          </div>
          <div className="right_btn">
            <button  onClick={handleClick}>Sign Up</button>
          </div>
        </div>
      </div>
      {activeTab === "about" && (
        <div className="about_tab">
          {/* Seamless Experience */}
          <div className="Seamless_Experience_wrap mt-5 position-relative">
            {/* <div className="container"> */}
            <div className="Seamless_Experience_multi_dots">
              <img src={DotsA} alt="" />
            </div>
            <div className="row">
              <div className="col-md-12 col-xl-6 position">
                <div className="Seamless_Experience_left pl-100">
                  <h5>Seamless Experience</h5>
                  <h1>
                    Lorem ipsum dolor sit amet consectetur. Eros sed malesuada
                    est.
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Pellentesque amet
                    sed sed nibh aliquam. Nunc.
                  </p>
                  <button onClick={handleClick} >Get Started</button>
                </div>
              </div>
              <div className="col-md-12 col-lg-6 "></div>
            </div>
            {/* </div> */}
          </div>

          {/* Sports Betting */}

          <div className="sports_betting_wrap">
            <img className="multi_dots" src={DotsB} alt="" />
            <div className="container">
              <div className="sports_betting_head mb-100">
                <h2>
                  <span>Wake Up To Your Very Own </span> <br />
                  Sports Betting Crystal Ball. Texting You.
                </h2>
                <p>Sports Betting Crystal Ball. Texting You.</p>
              </div>
              <div className="reliability_Edge_time">
              <div className="row">
  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
    <div className="reliability_Edge_time_Row_Box text-center">
      <img src={ReliabilityIcon} alt="Reliability Icon" />
      <h4>Reliability</h4>
      <p>
        Lorem ipsum dolor sit amet consectetur. Cras dolor accumsan amet fusce at massa urna vel tempor.
      </p>
    </div>
  </div>
  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
    <div className="reliability_Edge_time_Row_Box text-center">
      <img src={CompetetiveIcon} alt="Competitive Edge Icon" />
      <h4>Competitive Edge</h4>
      <p>
        Lorem ipsum dolor sit amet consectetur. Cras dolor accumsan amet fusce at massa urna vel tempor.
      </p>
    </div>
  </div>
  <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
    <div className="reliability_Edge_time_Row_Box text-center">
      <img src={TimesavingIcon} alt="Time-saving Icon" />
      <h4>Time-saving</h4>
      <p>
        Lorem ipsum dolor sit amet consectetur. Cras dolor accumsan amet fusce at massa urna vel tempor.
      </p>
    </div>
  </div>
</div>

              </div>
            </div>
          </div>

          {/* Section Prospects  */}

          <div className="prospects_main_wrap">
            <div className="container">
              <div className="prospects_A_Wrap row align-items-center mb-150">
                <div className="col-md-12 col-lg-6 mb-3">
                  <img className="ProspectesA_image" src={ProspectesA} alt="" />
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="prospects_box_wrap ">
                    <div className="prospects_main_head d-flex justify-content-between align-items-center pb-36">
                      <h6>
                        Prospects for <span>Players</span>
                      </h6>
                      <img src={ArrowRight} alt="" />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Proin purus auctor
                      etiam pellentesque. In blandit aliquam aliquet diam. Massa
                      dictum libero ultrices neque aenean id. Cursus ac feugiat
                      quisque sed nibh egestas non feugiat vel. Semper accumsan
                      lobortis eu quam faucibus ornare nibh. Auctor leo
                      ultricies.
                    </p>
                  </div>
                </div>
              </div>
              <div className="prospects_A_Wrap row align-items-center mb-150">
                <div className="col-md-12 col-lg-6">
                  <div className="prospects_box_wrap ">
                    <div className="prospects_main_head d-flex justify-content-between align-items-center pb-36">
                      <h6>
                        Prospects for <span>Agents</span>
                      </h6>
                      <img src={ArrowRight} alt="" />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Proin purus auctor
                      etiam pellentesque. In blandit aliquam aliquet diam. Massa
                      dictum libero ultrices neque aenean id. Cursus ac feugiat
                      quisque sed nibh egestas non feugiat vel. Semper accumsan
                      lobortis eu quam faucibus ornare nibh. Auctor leo
                      ultricies.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 mb-3">
                  <img className="ProspectesA_image" src={ProspectesB} alt="" />
                </div>
              </div>
              <div className="prospects_A_Wrap row align-items-center">
                <div className="col-md-12 col-lg-6 mb-3">
                  <img className="ProspectesA_image" src={ProspectesC} alt="" />
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="prospects_box_wrap ">
                    <div className="prospects_main_head d-flex justify-content-between align-items-center pb-36">
                      <h6>
                        Prospects for <span>Schools & Clubs</span>
                      </h6>
                      <img src={ArrowRight} alt="" />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet consectetur. Proin purus auctor
                      etiam pellentesque. In blandit aliquam aliquet diam. Massa
                      dictum libero ultrices neque aenean id. Cursus ac feugiat
                      quisque sed nibh egestas non feugiat vel. Semper accumsan
                      lobortis eu quam faucibus ornare nibh. Auctor leo
                      ultricies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

         
        </div>
      )}

      {activeTab === "pricing" && <div className="price_tab">
        
        <div className="Pricing_wrap">
        <div className="Select_the_best_Wrap">
            <div className="container">
              <div className="row">
          <div className="Select_the_best_content">
                <div className="col-md-12 col-lg-6">
                  <h6>Select the Best subscription Plan for You</h6>
                  <p>Lorem ipsum dolor sit amet consectetur. Pellentesque amet sed sed nibh aliquam. Nunc.</p>
                </div>
              </div>
            </div>
            </div>
          </div>
          {/* for players */}
          <div className="basic_gold_premium">
<div className="light_heading_wrap">
  <div className="container-fluid">
    <h6>For Players</h6>
  </div>
</div>
<div className="basic_gold_premium_cards">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Basic</p>
          <h5>$5/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Gold</p>
          <h5>$50/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6">
        <div className="card_wrap premium_Card">
          <p>Premium</p>
          <h5>$25/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
          </div>
          {/* For agents */}
          <div className="basic_gold_premium">
<div className="light_heading_wrap">
  <div className="container-fluid">
    <h6>For Agents</h6>
  </div>
</div>
<div className="basic_gold_premium_cards">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Basic</p>
          <h5>$5/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Gold</p>
          <h5>$50/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap premium_Card">
          <p>Premium</p>
          <h5>$25/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
          </div>

{/* for school */}
<div className="basic_gold_premium">
<div className="light_heading_wrap">
  <div className="container-fluid">
    <h6>For School</h6>
  </div>
</div>
<div className="basic_gold_premium_cards">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Basic</p>
          <h5>$5/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Gold</p>
          <h5>$50/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap premium_Card">
          <p>Premium</p>
          <h5>$25/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
          </div>

          {/* for amateur clubs */}
          <div className="basic_gold_premium">
<div className="light_heading_wrap">
  <div className="container-fluid">
    <h6>For Amateur Clubs</h6>
  </div>
</div>
<div className="basic_gold_premium_cards">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Basic</p>
          <h5>$5/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Gold</p>
          <h5>$50/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap premium_Card">
          <p>Premium</p>
          <h5>$25/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
          </div>

{/* For Professional clubs */}
<div className="basic_gold_premium">
<div className="light_heading_wrap">
  <div className="container-fluid">
    <h6>For Professional Clubs</h6>
  </div>
</div>
<div className="basic_gold_premium_cards">
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Basic</p>
          <h5>$5/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap">
          <p>Gold</p>
          <h5>$50/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
        <div className="card_wrap premium_Card">
          <p>Premium</p>
          <h5>$25/month</h5>
          <ul>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={GreenCheck} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
            <li><img src={RedCross} alt="" /> Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
          <div className="choose_learn_btns text-center">
            <button className="choose_btn">Choose</button>
            <button className="Learn_more">Learn more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
          </div>


        </div>
        </div>}


         {/* Sign up to the Prospects */}

         <div style={divStyle} className="signup_for_prospects_wrap">
            <div className="container-fluid p-l-r-70">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-5">
                  <div className="signup_for_prospects_left">
                    <h6>
                      Sign up to the
                      <span> Prospects</span>
                    </h6>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nunc vulputate libero et velit interdum, ac aliquet odio
                      mattis.
                    </p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-7">
                  <div className="signup_for_prospects_left">
                    <input type="text" placeholder="Email ID" />
                    <div className="free_demo_btn">
                      <button>Book a free demo</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      {/* footer */}
      <footer>
        <div className="container-fluid p-l-r-70">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="footer_left">
                <img src={Brand} alt="" />
                <h6>Lorem ipsum dolor sit </h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  vulputate libero et velit interdum, ac aliquet odio mattis.
                </p>
              </div>
            </div>
            <div className="col-xxl-6 col-md-12">
              <div className="footer_right">
                <div className="row">
                  <div className="col-md-12 col-xl-4 col-lg-6 mb-2 First_lis">
                    <h6>Contact</h6>
                    <ul>
                      <li>
                        <img src={HomeIcon} alt="" /> New York, NY 10012, US
                      </li>
                      <li>
                        <img src={MessageIcon} alt="" /> info@gmail.com
                      </li>
                      <li>
                        <img src={PhoneIcon} alt="" /> + 01 234 567 88
                      </li>
                      <li>
                        <img src={PrintIcon} alt="" /> + 01 234 567 89
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
                    <h6>Products</h6>
                    <ul>
                      <li>Platform</li>
                      <li>Discover</li>
                      <li>Resources</li>
                      <li>Jobs</li>
                    </ul>
                  </div>
                  <div className="col-md-12 col-xl-4 col-lg-6 mb-2">
                    <h6>Useful Links</h6>
                    <ul>
                      <li>Your Account</li>
                      <li>Help</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_social_icon d-flex align-items-center justify-content-center ">
              <img src={FacebookIcon} alt="" />
              <img src={TwitterIcon} alt="" />
              <img src={BrowserIcon} alt="" />
              <img src={LinkedIn} alt="" />
            </div>
            <div className="copyright_wrap">
              <p>© 2022 Copyright: @Prospects Ltd.</p>
            </div>
          </div>
        </div>
      </footer>

    </div>
  );
};

export default Landing;
