import React, { useState, useEffect } from 'react';
import searchLogo from ".././../assets/search.svg";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getAllPlayerSlice, searchPlayerSlice } from '../../redux/slice/GetallPlayer';
import { useDispatch, useSelector } from 'react-redux';
import SearchPlayer from './SearchPlayer';

const Players = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [players, setPlayers] = useState([]); 
  const [allPlayers, setAllPlayers] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const User = JSON.parse(localStorage.getItem("loginData"));
  const Token = User?.token;
  const dispatch = useDispatch();
  const player = useSelector((state) => state.players);
  const filterplayers = Array.isArray(player) ? player : player.filterplayers || [];
  
  useEffect(() => {
    const fetchAllPlayers = async () => {
      setIsLoading(true);
      try {
        const res = await dispatch(getAllPlayerSlice());
        if (res && Array.isArray(res.payload)) { 
          setAllPlayers(res.payload); 
          setPlayers(res.payload); 
        } else {
          setAllPlayers([]); 
          setPlayers([]); 
        }
      } catch (error) {
        console.error('Error fetching all players:', error);
        setAllPlayers([]); 
        setPlayers([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllPlayers();
  }, [dispatch]);

  
  const handleSearch = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === '') {
      setPlayers(allPlayers);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
     
      const res = await dispatch(searchPlayerSlice({ name: value, token: Token }));
      if (res && Array.isArray(res.data)) { 
        setPlayers(res.data);
      } else {
        setPlayers([]); 
      }
    } catch (error) {
      console.error('Error fetching players:', error);
      setPlayers([]); 
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h6 style={{ color: '#fff' }}>Players</h6>
      <div className="players">
        <div className="">
          <div className="search-sectionn">
            <img src={searchLogo} alt="Search" />
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="player-btn row">
          <DropdownButton
            id="dropdown-button"
            title="Filters"
            className="player-dropdown custom-filter-dropdown player-btn1 col-12 col-md-6 col-lg-4 col-xl-2 mb-1 text-center"
          >
            <Dropdown.Item className="custom-dropdown-item">
              <input type="checkbox" id="tierRanking" />
              <label htmlFor="tierRanking"> Tier Ranking <i className="down-icon"></i></label>
            </Dropdown.Item>
            <Dropdown.Item className="custom-dropdown-item">
              <input type="checkbox" id="premiumNonRanking" />
              <label htmlFor="premiumNonRanking"> Premium NON-Ranking Paying Users</label>
            </Dropdown.Item>
            <Dropdown.Item className="custom-dropdown-item">
              <input type="checkbox" id="basicNonRanking" />
              <label htmlFor="basicNonRanking"> Basic NON-Ranking Paying Users</label>
            </Dropdown.Item>
            <Dropdown.Item className="custom-dropdown-item">
              <input type="checkbox" id="professionalPlayers" />
              <label htmlFor="professionalPlayers"> Professional Players</label>
            </Dropdown.Item>
            <Dropdown.Item className="custom-dropdown-item">
              <input type="checkbox" id="amateurPlayers" />
              <label htmlFor="amateurPlayers"> Amateur Player</label>
            </Dropdown.Item>
          </DropdownButton>

          <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn2">Trending Player</button>
          <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn3">Professional</button>
          <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn4">Amateur</button>
          <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn5">Right</button>
          <button className="col-12 col-md-6 col-lg-4 col-xl-2 mb-1 player-btn6">Left</button>
        </div>
      </div>

      <div>
      {isLoading ? (
          <p>Loading players...</p>
        ) : (        
                
              <SearchPlayer  filterplayer={filterplayers} />
            
            
        )}



      </div>
    </div>
  );
};

export default Players;
