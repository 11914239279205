import React, { useState, useEffect } from "react";
import {  gatAllSchoolApi, SearchSchoolApi } from "../../../../redux/api/user";

const ClubTab = ({ setFieldValue }) => {
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [players, setPlayers] = useState([]); // New state for players
  const User = JSON.parse(localStorage.getItem("loginData"));
  const Token = User?.token;

  useEffect(() => {
    const fetchClubs = async () => {
      try {
        const response = await gatAllSchoolApi();        
        setClubs(response.data);
      } catch (error) {
        console.error("Error fetching clubs:", error);
      }
    };

    fetchClubs();
  }, []);

  const handleClubClick = (schoolId) => {
    setSelectedClub(schoolId);
    setFieldValue("currentSchoolId", schoolId);
  };

  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    
    if (value) {
      try {
        const response = await SearchSchoolApi({ name: value, token: Token });
        
        setPlayers(response.data.data); 
      } catch (error) {
        console.error("Error searching players:", error);
      }
    } else {
      setPlayers([]);
    }
  };

  


  return (
    <>
      <div className="mt-3 align-items-center">
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <h2 className="progressbar-below-form">Are you a part of Club?</h2>
        </div>
        <div className="Club_search_main_wrap d-flex justify-content-center align-items-center">
          <input
            className="club-search"
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        <div className="mb-5">
          <div className="row">   
            {/* Render players if there are any matching results */}
            {players.length > 0 ? (
              players.map((player) => (
                 
                  
                <div
                  key={player._id} 
                  className="col-sm-6 col-md-6 col-lg-3 text-center mb-5"
                >
                  <div   className={`club-image ${
                      selectedClub === player._id ? "selected" : ""
                    }`}
                    onClick={() => handleClubClick(player._id)} >
                    <img
                      src={`https://mosis-backend.cradle.services${process.env.PUBLIC_URL + player.face_picture}`} // Use player's image URL
                      alt={player.name} 
                      className="image-cursor"
                      style={{ width: '100%', height: 'auto' }} 
                    />
                  </div>
                </div>
              ))
            ) : (
              // If no players are found, display clubs
              clubs.map((club) => (
                <div
                  key={club._id} 
                  className="col-sm-6 col-md-6 col-lg-3 text-center mb-5"
                >
                  <div
                    className={`club-image ${
                      selectedClub === club._id ? "selected" : ""
                    }`}
                    onClick={() => handleClubClick(club._id)} 
                  >
                    <img
                      src={`https://mosis-backend.cradle.services${process.env.PUBLIC_URL + club.face_picture}`}
                      alt={club.Name} 
                      className="image-cursor"
                      style={{ width: '100%', height: 'auto' }} 
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClubTab;
