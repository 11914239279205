import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlayerSlice } from "../../redux/slice/GetallPlayer";
import { followPlayerApi, unfollowPlayerApi } from "../../redux/api/user";
import { getPlayerSlice } from "../../redux/slice/GetPlayer";
import { toast } from "react-toastify";


const Playerslist = () => {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("loginData"));
  const rolename = role?.role;
  const userId = role?._id;
  const Token = role?.token;
  const player = useSelector((state) => state.players);
  const AllPlayer = useMemo(() => {
    return Array.isArray(player) ? player : player.players || [];
  }, [player]); 


  const [players, setPlayers] = useState([]);
  const [followStatus, setFollowStatus] = useState({});
  const [playerr, setPlayerr] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    setPlayers(AllPlayer);
  }, [AllPlayer]);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        await dispatch(getAllPlayerSlice());
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchPlayers();
  }, [dispatch]);


  const roleObj = [
    { get: "player", value: "Player" },
    { get: "agent", value: "Agent" },
    { get: "soccer_school", value: "SoccerSchool" },
    { get: "professional_club", value: "Club" },
    { get: "amateur_club", value: "Club" },
  ];

  useEffect(() => {
    const getProfilePicture = async () => {
      const endPoint = roleObj.filter((i) => i.get === rolename);
      try {
        const res = await dispatch(
          getPlayerSlice({ userId: userId, endPoint: endPoint[0] })
        );
        if (res.payload.isSuccess && res.payload.data[0]) {
          setPlayerr(res.payload.data[0]);
        }
      } catch (error) {
        console.error("Failed to fetch profile picture:", error);
      }
    };

    if (userId) {
      getProfilePicture();
    }
  }, [userId, dispatch, rolename]);

  const playerid = playerr?._id;

  const handleSubmit = async (playerId) => {
    try {
      const isFollowing = followStatus[playerId];
      const targetId = playerId;

      if (isFollowing) {
        const unfollow = await unfollowPlayerApi({
          playerId: playerid,
          token: Token,
          targetId,
        });
        if (unfollow.message) {
          toast("Player Unfollowed");
          setFollowStatus((prevStatus) => ({
            ...prevStatus,
            [playerId]: false,
          }));
        }
      } else {
        const follow = await followPlayerApi({
          playerId: playerid,
          token: Token,
          targetId,
        });
        if (follow.message) {
          toast("Player Followed");

       
          await fetchNewPlayer();

          setFollowStatus((prevStatus) => ({
            ...prevStatus,
            [playerId]: true,
          }));
        }
      }
    } catch (error) {
      console.error("Error toggling follow status:", error);
      toast.error("player allready follow")
    }
  };

  // Fetch a new player to add to the list
  const fetchNewPlayer = async () => {
    try {
      const res = await dispatch(getAllPlayerSlice());
      if (res.payload.data && res.payload.data.length > 0) {
        // Select a new player that is not already in the current list
        const existingIds = players.map((p) => p._id);
        const newPlayers = res.payload.data.filter(
          (p) => !existingIds.includes(p._id)
        );

        if (newPlayers.length > 0) {
          // Add the first new player found
          setPlayers((prevPlayers) => [...prevPlayers, newPlayers[0]]);
        }
      }
    } catch (error) {
      console.error("Error fetching a new player:", error);
    }
  };

  return (
    <div>
      <div className="players_list_main_wrap mt-5">
        <div className="container">
          <div className="row flex_wrap_wrap">
            {players &&
              players.slice(0, 5).map((player) => (
                <div key={player._id} className="width-202px col-12 mb-4">
                  <div className="card custom-card">
                    <div className="card-body">
                      <Link
                        to={`/${rolename}/playerprofile`}
                        state={{ player }}
                      >
                        <img
                          src={`https://mosis-backend.cradle.services${player.face_picture}`}
                          alt=""
                        />
                      </Link>
                      <h6>{player.Name}</h6>
                      {rolename === "player" ? (
                        <button onClick={() => handleSubmit(player._id)}>
                          {followStatus[player._id] ? "Unfollow" : "Follow"}
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            navigate(`/${rolename}/playerprofile`, {
                              state: { player },
                            })
                          }
                        >
                          Check
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Playerslist;
