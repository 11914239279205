



import React, { useState } from "react";

const workedWithPlayers = [
  { id: "66dbf9b045a9f43bc9edc396", name: "Test player" },
  { id: "66dee9ed2549adb7c1339145", name: "Test" },
  { id: "66e5317b8c846cd8f026a740", name: "Test player 11" },
];

const AgentSecTwo = () => {
  const [experiences, setExperiences] = useState([
    { location: "", workedWithPlayers: "", achievements: "", startDate: { month: "", year: "" }, endDate: { month: "", year: "" } },
  ]);

  const [errors, setErrors] = useState({});

  const handleInputChange = (index, field, value) => {
    const newExperiences = [...experiences];

    if (field.includes("startDate") || field.includes("endDate")) {
      const [key, subfield] = field.split(".");
      newExperiences[index][key][subfield] = value;
    } else if (field === "workedWithPlayers") {
      const selectedPlayer = workedWithPlayers.find((player) => player.id === value);
      newExperiences[index][field] = selectedPlayer ? selectedPlayer.id : "";
    } else {
      newExperiences[index][field] = value;
    }

    setExperiences(newExperiences);
  };

  const handleAddExperience = () => {
    setExperiences([
      ...experiences,
      { location: "", workedWithPlayers: "", achievements: "", startDate: { month: "", year: "" }, endDate: { month: "", year: "" } },
    ]);
  };

  const handleRemoveExperience = (index) => {
    const newExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(newExperiences);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    experiences.forEach((exp, index) => {
      if (!exp.location) newErrors[`location_${index}`] = "Location is required";
      if (!exp.player) newErrors[`workedWithPlayers_${index}`] = "Player is required";
      if (!exp.achievements) newErrors[`achievements_${index}`] = "Achievements are required";
      if (!exp.startDate.month || !exp.startDate.year) newErrors[`startDate_${index}`] = "Start date is required";
      if (!exp.endDate.month || !exp.endDate.year) newErrors[`endDate_${index}`] = "End date is required";
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      //console.log("Form submitted successfully", experiences);
    }
  };

  return (
    <>
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <h2 className="progressbar-below-form">Experience</h2>
      </div>
      <div className="Form_main_wrap_for_details">
        <form onSubmit={handleSubmit}>
          {experiences.map((experience, index) => (
            <div key={index} className="mb-4">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="d-block">Location</label>
                    <input
                      type="text"
                      className="player-body-input p-4"
                      value={experience.location}
                      onChange={(e) => handleInputChange(index, "location", e.target.value)}
                    />
                    {errors[`location_${index}`] && (
                      <div className="error-message text-danger">{errors[`location_${index}`]}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Players You have worked with</label>
                    <select
                      value={experience.player}
                      onChange={(e) => handleInputChange(index, "workedWithPlayers", e.target.value)}
                      className="player-body-input"
                      style={{ padding: "10px", height: "50px" }}
                    >
                      <option value="" label="Select " />
                      {workedWithPlayers.map((player) => (
                        <option key={player.id} value={player.id}>
                          {player.name}
                        </option>
                      ))}
                    </select>
                    {errors[`workedWithPlayers_${index}`] && (
                      <div className="error-message text-danger">{errors[`workedWithPlayers_${index}`]}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-3 mt-3">
                <label className="form-label">Give us an overview of your Achievements</label>
                <textarea
                  className="form-control player-body-input-1"
                  value={experience.achievements}
                  onChange={(e) => handleInputChange(index, "achievements", e.target.value)}
                  rows="3"
                />
                {errors[`achievements_${index}`] && (
                  <div className="error-message text-danger">{errors[`achievements_${index}`]}</div>
                )}
              </div>

              <div className="row">
                <div className="form-group col-sm-6">
                  <label className="d-block">Start Date</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="player-body-input-3 mr-2"
                      value={experience.startDate.month}
                      onChange={(e) => handleInputChange(index, "startDate.month", e.target.value)}
                      placeholder="Month"
                    />
                    <input
                      type="text"
                      className="player-body-input-3 mx-2"
                      value={experience.startDate.year}
                      onChange={(e) => handleInputChange(index, "startDate.year", e.target.value)}
                      placeholder="Year"
                    />
                  </div>
                  {errors[`startDate_${index}`] && (
                    <div className="error-message text-danger">{errors[`startDate_${index}`]}</div>
                  )}
                </div>
                <div className="form-group col-sm-6">
                  <label className="d-block">End Date</label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="player-body-input-3 mr-2"
                      value={experience.endDate.month}
                      onChange={(e) => handleInputChange(index, "endDate.month", e.target.value)}
                      placeholder="Month"
                    />
                    <input
                      type="text"
                      className="player-body-input-3 mx-2"
                      value={experience.endDate.year}
                      onChange={(e) => handleInputChange(index, "endDate.year", e.target.value)}
                      placeholder="Year"
                    />
                  </div>
                  {errors[`endDate_${index}`] && (
                    <div className="error-message text-danger">{errors[`endDate_${index}`]}</div>
                  )}
                </div>
              </div>

              {index > 0 && (
                <div className="experience-remove mt-4 text-danger">
                  <p onClick={() => handleRemoveExperience(index)}>Remove Experience</p>
                </div>
              )}
            </div>
          ))}

          <div className="experience-add mt-4">
            <p onClick={handleAddExperience}>+ Add More</p>
          </div>

         
        </form>
      </div>
    </>
  );
};

export default AgentSecTwo;
