import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Subscription = () => {
    const [role, setRole] = useState({}); 
    const navigate = useNavigate();
    

    useEffect(() => {
      const fetchRoleFromLocalStorage = () => {
        const loginData = localStorage.getItem("loginData");
  
        if (loginData && loginData !== "undefined") {
          try {
            const parsedRole = JSON.parse(loginData);
            setRole(parsedRole); 
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        } else {
          console.warn("loginData is not available or is undefined");
        }
      };
  
      fetchRoleFromLocalStorage(); 
    }, []);
    const rolename = role?.role;
    const userId = role?._id;
    const userToken = role?.token;

  return (
    <>
    <div className="container">
      {/* Subscription Plan */}
      <div className="subs_box_wrap mb-80">
        <div className="subs_head">
          <h5>Subscription Plan</h5>
        </div>
        <div className="subscription_box_wrap align-items-center row">
          <div className="subscription_box_left col-md-6 p-0">
            <h6>premium</h6>
            <ul>
              <li>Lorem ipsum dolor sit amet consectetur. </li>
              <li>Dictum sed elementum dictum imperdiet. </li>
              <li>Turpis leo mollis justo hendrerit eu vitae at. </li>
              <li>Amet elit et ornare adipiscing. </li>
            </ul>
          </div>
          <div className="subscription_box_right text-end col-md-6 p-0">
            <h4>$149/month</h4>
            <p>Last updated on 12/05/2023</p>
            {/* <button onClick={handleNavigate}>Change</button> */}
            <button
              onClick={() => navigate(`/${rolename}/Changesubs`)}
            >
              Change
            </button>
          </div>
        </div>
      </div>

      {/* Ranking Plan */}

      <div className="subs_box_wrap for_ranking mb-80">
        <div className="subs_head">
          <h5>Ranking Plan</h5>
        </div>
        <div className="subscription_box_wrap align-items-center row">
          <div className="subscription_box_left col-md-6 p-0">
            <h6>Player Tier 3</h6>
            <ul>
              <li>Lorem ipsum dolor sit amet consectetur. </li>
              <li>Dictum sed elementum dictum imperdiet. </li>
              <li>Turpis leo mollis justo hendrerit eu vitae at. </li>
              <li>Amet elit et ornare adipiscing. </li>
            </ul>
          </div>
          <div className="subscription_box_right text-end col-md-6 p-0">
            <h4>$149/month</h4>
            <p>Last updated on 12/05/2023</p>
            <button
              onClick={() => navigate(`/${rolename}/Changesubs`)}
            >
              Change
            </button>
          </div>
        </div>
      </div>

      {/* Billing Preferences */}

      <div className="subs_box_wrap for_Billing_pref">
        <div className="subs_head">
          <h5>Billing Preferences</h5>
        </div>
        <div className="subscription_box_wrap align-items-center row">
          <div className="subscription_box_left d-flex justify-content-between align-items-center col-md-6 p-0">
            <div class="radio-container">
              <label class="custom-radio">
                <input type="radio" name="radio-group" />
                <span class="radio-checkmark"></span>
                Bill Monthly
              </label>
            </div>
            <div className="radio_btn_info">
              <h3>$0/Month</h3>
            </div>
          </div>
          <div className="subscription_box_right d-flex justify-content-between align-items-center col-md-6 p-0">
            <div class="radio-container">
              <label class="custom-radio">
                <input type="radio" name="radio-group" />
                <span class="radio-checkmark"></span>
                Bill Yearly
              </label>
            </div>
            <div className="radio_btn_info">
              <h3>$0/Year</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Subscription