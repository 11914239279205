import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import ModalX from "../../../assets/modal-x.svg";
import UploadIconA from "../../../assets/upload_iconA.svg";
import UploadIconB from "../../../assets/upload_iconB.svg";
import ArrowDown from "../../../assets/arrow-down-bold.svg";
import "./Modal.css";
import { createReelApi } from "../../../redux/api/user";


function ModalDialog({ isShow, onClose }) {
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [isThirdModalOpen, setIsThirdModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('video');
  const [title, setTitle] = useState('');
  const [inputKeywords, setInputKeywords] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoUrl, setVideoUrl] = useState('');

  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  useEffect(() => {
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    };
  }, [videoUrl]);

  const handleFirstModalClose = () => {
    onClose();
  };

  const handlePostVideo = () => {
    if (!file) {
      toast.warn('Please select a video to upload.');
      return;
    }
    setIsSecondModalOpen(true);
    handleFirstModalClose();
  };

  const handleSecondModalClose = () => {
    setIsSecondModalOpen(false);
  };

  const handleThirdModalClose = () => {
    setIsThirdModalOpen(false);
  };

  const handleCreateNewPlaylist = () => {
    setIsThirdModalOpen(true);
    setIsSecondModalOpen(false);
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('video/')) {
      setFile(selectedFile);
      setVideoUrl(URL.createObjectURL(selectedFile));
    } else {
      toast.warn('Please select a valid video file.');
    }
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setImage(selectedFile);
    } else {
      toast.warn('Please select a valid image file.');
    }
  };

  const handleKeywordChange = (e) => {
    const newKeywords = e.target.value.split(' ').filter(keyword => keyword.trim() !== '');
    setInputKeywords(e.target.value);
    setKeywords(newKeywords);
  };

  const handleSavePlaylist = async (e) => {
    e.preventDefault();
    setIsThirdModalOpen(false);
    setIsSecondModalOpen(true);

    const loginData = localStorage.getItem("loginData");

    let user = {};
    if (loginData && loginData !== 'undefined') {
      try {
        user = JSON.parse(loginData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else {
      console.warn('loginData is not available or is undefined');
    }

    if (!user) {
      toast.error('User not found.');
      return;
    }

    if (activeSection === 'reel') {
      if (!title || keywords.length === 0 || !file) {
        toast.warn('Please fill all fields and upload a file.');
        return;
      }

      setLoading(true);

      const formData = new FormData();
      formData.append('video', file);
      formData.append('title', title);
      formData.append('keywords', keywords.join(','));
      formData.append('userId', user._id);

      try {
        const response = await createReelApi(user._id, user.token, formData, (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        });

        setLoading(false);
        setUploadProgress(0);
        //console.log('Upload response:', response.data);
        //console.log('Upload response:', response);
        toast.success('Video uploaded successfully!');
        // localStorage.setItem('newReelUpload', 'success');
        // window.location.reload();
        handleFirstModalClose();
        handleSecondModalClose();
      } catch (error) {
        setLoading(false);
        setUploadProgress(0);
        console.error('Error creating reel:', error);
        toast.error('Failed to upload video.');
      }
    }
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <div className="modal_for_video_reels">
        <Modal show={isShow} onHide={handleFirstModalClose}>
          <div className="create_post_top">
            <p>Create Post</p>
          </div>
          <Modal.Header>
            <div className="tab_on_top">
              <a
                className={activeSection === 'video' ? 'active_video' : ''}
                onClick={() => handleSectionChange('video')}
              >
                Video
              </a>
              <a
                className={activeSection === 'reel' ? 'active_video' : ''}
                onClick={() => handleSectionChange('reel')}
              >
                Reel
              </a>
            </div>
            <span className="modal_close_cross" onClick={handleFirstModalClose}>
              <img className="cursor" src={ModalX} alt="cross-icon" />
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className="modal_body_form_wrap">
              <form>
                <div className="form_wrap">
                  <label htmlFor="title">Title</label>
                  <textarea
                    className='textArea'
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="form_wrap">
                  <label htmlFor="description">Description</label>
                  <textarea
                    className='textArea description_height'
                    id="description"
                  />
                </div>
                <div className="form_wrap">
                  <label htmlFor="keywords">Keywords</label>
                  <input
                    type="text"
                    value={inputKeywords}
                    onChange={handleKeywordChange}
                    placeholder="Type keywords "
                  />
                </div>
                
                <div className="form_wrap">
                  <label htmlFor="uploadVideo">Upload </label>
                  <div className="upload_btn">
                    <input
                      type="file"
                      accept="video/*"
                      id="uploadVideo"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <div
                      className="upload_icon"
                      onClick={() => fileInputRef.current.click()}
                      style={{ cursor: 'pointer' }}
                    >
                      <img src={UploadIconA} alt="Upload video" />
                    </div>
                  </div>
                </div>

                <div className="form_wrap">
                  {/* <label htmlFor="uploadImage">Upload Image</label> */}
                  <div className="upload_btn mt-32">
                    <input
                      type="file"
                      accept="image/*"
                      id="uploadImage"
                      ref={imageInputRef}
                      onChange={handleImageChange}
                      className="visually"
                    />
                    <div
                      className="upload_icon"
                      onClick={() => imageInputRef.current.click()}
                      style={{ cursor: 'pointer' }}
                    >
                      <img src={UploadIconB} alt="Upload image" />
                    </div>
                  </div>
                </div>

                <div className="post_video_btn text-center">
                  <button type="button" onClick={handlePostVideo}>
                    Post Video
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <Modal show={isSecondModalOpen} onHide={handleSecondModalClose}>
        <div className="modal_body_form_wrape">
          <Modal.Body>
            <form>
              {videoUrl && (
                <div className="image mb-5">
                  <video width="100%" height='500px' controls>
                    <source src={videoUrl} type={file?.type} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
              <div className="second_modal_text_wrap">
                <h2>
                  Vancouver Whitecaps FC vs. Inter Miami CF | First-Ever
                  Encounter | Full Match Highlights
                </h2>
                <p>
                  For more information about MLS, go to the league's official{" "}
                  <br /> website: <span>http://www.MLSsoccer.com</span>
                </p>
              </div>
              <div className="keywords_wrap">
                <h6>Keywords</h6>
                {keywords.map((keyword, index) => (
                  <div className="keyword_wrap encounter_buttons_wrap " key={index}>
                    {/* <button className="mr-16">First-Ever Encounter</button> */}
                    <button >{keyword}</button>
                  </div>
                ))}
                <div className="audience_wrap">
                  <h6>Audience</h6>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        marginLeft: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="firstCheckbox"
                        style={{ width: "15px", height: "15px" }}
                      />
                      <label
                        htmlFor="firstCheckbox"
                        style={{ marginLeft: "30px", color: "white" }}
                      >
                        Yes, It is made for kids
                      </label>
                    </div>
                    <div
                      style={{
                        marginLeft: "50px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="secondCheckbox"
                        style={{ width: "15px", height: "15px" }}
                      />
                      <label
                        htmlFor="secondCheckbox"
                        style={{ marginLeft: "30px", color: "white" }}
                      >
                        No, It is not made for kids
                      </label>
                    </div>
                  </div>
                </div>
                <div className="playlist_main_wrap">
                  <h6>Playlist</h6>
                  <p>
                    Add your video to one or more playlists to organize videos for
                    your viewers
                  </p>

                  <div className="dropdown">
                    <button className="dropdown-button" onClick={toggleDropdown}>
                      Select <img src={ArrowDown} alt="" />
                    </button>
                    {isDropdownOpen && (
                      <div
                        className="dropdown-content"
                        style={{
                          width: "100%",
                          backgroundColor: "#171A19",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          position: "absolute",
                          zIndex: "1000",
                        }}
                      >
                        <div className="search-box" style={{ padding: "10px" }}>
                          <input
                            type="text"
                            placeholder="Search for playlist"
                            style={{
                              height: "49px",
                              width: "100%",
                              padding: "8px",
                              boxSizing: "border-box",
                              backgroundColor: "#2F3231",
                              border: "1px solid #64AFB1",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                        <div
                          className="create-playlist"
                          style={{
                            padding: "15.5px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#64AFB1",
                            fontSize: "23px",
                            justifyContent: "center",
                            borderTop: "1px solid #5F5B6E",
                          }}
                          onClick={handleCreateNewPlaylist}
                        >
                          <i
                            className="fa fa-plus"
                            style={{ marginLeft: "20px" }}
                          ></i>
                          <span>Create new playlist</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="save_btn_wrap text-center">
                    <button onClick={handleSavePlaylist} disabled={loading}>
                      {loading ? 'Uploading...' : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>

      <Modal show={isThirdModalOpen} onHide={handleThirdModalClose}>
        <Modal.Body>
          <div className="modal_body_form_wrap">
            <form>
              <div className="form_wrap">
                <label htmlFor="playlistName">Title</label>
                <input type="text" id="playlistName" />
              </div>
              <div className="form_wrap description_height">
                <label htmlFor="description">Description</label>
                <input type="text" />
              </div>
              <div>
                <h6 className="visibility_h6">Visibility</h6>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "46px" }}>
                  <div
                    style={{
                      marginLeft: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="firstCheckbox"
                      style={{ width: "15px", height: "15px", fontSize: "20px" }}
                    />
                    <label
                      htmlFor="firstCheckbox"
                      style={{
                        marginLeft: "10px",
                        marginBottom: "0",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Public
                    </label>
                  </div>
                  <div
                    style={{
                      marginLeft: "150px",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "20px",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="secondCheckbox"
                      style={{ width: "15px", height: "15px", fontSize: "20px" }}
                    />
                    <label
                      htmlFor="secondCheckbox"
                      style={{ marginLeft: "10px", marginBottom: "0", color: "white" }}
                    >
                      Private
                    </label>
                  </div>
                  <div
                    style={{
                      marginLeft: "150px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="thirdCheckbox"
                      style={{ width: "15px", height: "15px", fontSize: "20px" }}
                    />
                    <label
                      htmlFor="thirdCheckbox"
                      style={{
                        marginBottom: "0",
                        marginLeft: "10px",
                        color: "white",
                        fontSize: "20px",
                      }}
                    >
                      Unlisted
                    </label>
                  </div>
                </div>
              </div>
            </form>
            <div className="post_video_btn text-center">
              <button type="button" onClick={handleSavePlaylist}>
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalDialog;

