import React from 'react'
import match_banner1 from "../../assets/Mask group.png"
import match_banner2 from "../../assets/Mask group (1).png"

const Matches = () => {
    return (
        <>
                  <h6 style={{color:'#fff'}}>Matches</h6>       
            
            <div className="container">  
            <div className="home_head_wrap">
                  <h6>Recent Matches</h6> 
                </div> 
            <div className="row">
                <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                {/* <div className="text2"> */}
                                <span className='text2'>Soccer - Professional</span>
                                {/* </div> */}
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                {/* <div className="text2"> */}
                                <span className='text2'>Soccer - Professional</span>
                                {/* </div> */}
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>



                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                {/* <div className="text2"> */}
                                <span className='text2'>Soccer - Professional</span>
                                {/* </div> */}
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>


                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                {/* <div className="text2"> */}
                                <span className='text2'>Soccer - Professional</span>
                                {/* </div> */}
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>


                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                {/* <div className="text2"> */}
                                <span className='text2'>Soccer - Professional</span>
                                {/* </div> */}
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>


                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                {/* <div className="text2"> */}
                                <span className='text2'>Soccer - Professional</span>
                                {/* </div> */}
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>


                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                <span className='text2'>Soccer - Professional</span>
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                <span className='text2'>Soccer - Professional</span>
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                <span className='text2'>Soccer - Professional</span>
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                <span className='text2'>Soccer - Professional</span>
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                <span className='text2'>Soccer - Professional</span>
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className="match-div">
                    <div className=''>
                        <a href="/allmatches">
                        <div className="match-banner">
                            <img src={match_banner1} alt="" className='logo1' />
                            <img src={match_banner2} alt="" className='logo2' />
                        </div>
                        </a>
                        <div className="match-text">
                            <div className="text1">
                                <p>Lasc vs Keithston match _ 20-12-2023 </p>
                                {/* <div className="text2"> */}
                                <span className='text2'>Soccer - Professional</span>
                                {/* </div> */}
                                <div className="text3">
                                    <span>Today  .  30K Views</span>
                                </div>
                            </div>
                            <div className="match-icon">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </div>
                        </div>
                    </div>
                </div>                  
           
                </div>
               
               
              
               

              
             

            </div>
        </>

    )
}

export default Matches