
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { myFollowingApi, unfollowPlayerApi } from '../../redux/api/user';
import { getPlayerSlice } from '../../redux/slice/GetPlayer';



const MyConnections = () => {
  const role = JSON.parse(localStorage.getItem('loginData')) 
  const rolename= role?.role 
  const userId = role?._id;
  const Token = role?.token;


const dispatch = useDispatch()
const [players, setPlayers] = useState([]);
const [player, setPlayer] = useState([]);
const navigate = useNavigate()



const roleObj = [
    {
      get: "player",
      value: "Player",
    },
    {
      get: "agent",
      value: "Agent",
    },
    {
      get: "soccer_school",
      value: "SoccerSchool",
    },
    {
      get: "professional_club",
      value: "Club",
    },
    {
      get: "amateur_club",
      value: "Club",
    },
  ];


  useEffect(() => {
    const getProfilePicture = async () => {
      const endPoint = roleObj.filter((i) => i.get === rolename);
      try {
        const res = await dispatch(getPlayerSlice({userId: userId,endPoint: endPoint[0]}));
        if (res.payload.isSuccess && res.payload.data[0]) {
          setPlayer(res.payload.data[0]); // Set profile picture
        }
      } catch (error) {
        console.error("Failed to fetch profile picture:", error);
      }
    };

    if (userId) {
      getProfilePicture();
    }
  }, []);


const playerId = player?._id



useEffect(() => {
    const fetchPlayers = async () => {

        try {
            const res = await myFollowingApi({playerId:playerId,token: Token})
            // console.log('API Response:', res);
            
            if (res.following) {
                setPlayers(res.following); 
            }
        } catch (error) {
            // toast.error('Failed to fetch players');myFollowingApi
        }
    };
if (playerId) {
    
    fetchPlayers();
}
}, [dispatch,playerId]);



const handleSubmit = async (playerid) => {
  try {
    const targetId = playerid; 
   const res =   await unfollowPlayerApi({ playerId: playerId, token: Token, targetId });
   if(res){
      toast("Player Unfollowed")     
   }
  } catch (error) {
    console.error("Error toggling follow status:", error);
  }
};





  return (
    <div>
 <div>
      <div className="players_list_main_wrap">
      <div className="container">
      <div className="row ">
      {players.map((player) => (
        <div key={player._id} className="width-202px col-md-12 mb-4">
          <div className="card custom-card">
            <div className="card-body">
              <Link to={`/${rolename}/playerprofile`} state={{player}} >
             <img src={`https://mosis-backend.cradle.services${player.face_picture}`} alt="" />
             </Link>
             <h6>{player.Name}</h6>
             <button onClick={() => handleSubmit(player._id)}>Unfollow</button>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
      </div>
    </div> 

    </div>
  )
}

export default MyConnections



