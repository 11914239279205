import React, { useState } from 'react';
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateUserApi } from '../../redux/api/user';
import './Settings.css';

export const General = () => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const loginData = localStorage.getItem("loginData");
  let getRole = {}; // Default value if parsing fails
  if (loginData && loginData !== 'undefined') {
    try {
      getRole = JSON.parse(loginData);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  } else {
    console.warn('loginData is not available or is undefined');
  }
  // //console.log('getRole', getRole);

  const Userid = getRole?._id; 
  const userRole = getRole?.role;
  const userToken = getRole?.token;
  const name = getRole?.Name || "";

  const [firstName, lastName] = name.split(" ");
  const [username, setUsername] = useState({
    Fname: firstName || "",
    Lname: lastName || "",
  });

  const formik = useFormik({
    initialValues: {
      Name: `${username.Fname} ${username.Lname}` || "",
      dob: { day: "", month: "", year: "" },
      gender: "",
      number: getRole?.number || "",
      email: getRole?.email || "",
    },
    enableReinitialize: true,

    onSubmit: async (values) => {
      const payload = {
        ...values,
        id: Userid,
        role: userRole,
        token: userToken
      };
      //console.log('payload', payload);
      try {
        const response = await updateUserApi({ id: Userid, token: userToken, data: payload });
        //console.log("response:", response);

        if (response && response.isSuccess) {
          navigate(`/${userRole}/`);
          toast.success("Profile updated successfully!");
        } else {
          console.error("Error in API response:", response);
          toast.error("Error updating profile: " + (response.message || 'Unknown error'));
        }
      } catch (error) {
        console.error("Error during API call:", error);
        toast.error("Error updating profile");
      }
    }
  });

  const handleGenderChange = (gender) => {
    formik.setFieldValue("gender", gender);
    setDropdownOpen(false); // Close dropdown after selection
  };

  const validatePhoneNumber = (value) => {
    if (!/^\d{0,10}$/.test(value)) {
      return "Phone number must be 10 digits";
    }
    return undefined;
  };

  const validateDateOfBirth = (value, field) => {
    let max;
    switch (field) {
      case "day":
        max = 31;
        return /^\d{1,2}$/.test(value) &&
          (value === "" ||
            (parseInt(value, 10) >= 1 && parseInt(value, 10) <= max))
          ? undefined
          : "Invalid day";
      case "month":
        max = 12;
        return /^\d{1,2}$/.test(value) &&
          (value === "" ||
            (parseInt(value, 10) >= 1 && parseInt(value, 10) <= max))
          ? undefined
          : "Invalid month";
      case "year":
        return /^\d{0,4}$/.test(value) ? undefined : "Invalid year";
      default:
        return undefined;
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="mb-3 col-sm-6">
            <label htmlFor="Name" className="form-label text-white">
              First Name
            </label>
            <input
              type="text"
              id="Name"
              name="Name"
              className="form-control custom-input"
              placeholder="First Name"
              value={username.Fname}
              onChange={(e) =>
                setUsername({ ...username, Fname: e.target.value })
              }
            />
          </div>
          <div className="mb-3 col-sm-6">
            <label htmlFor="lastName" className="form-label text-white">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="form-control custom-input"
              placeholder="Last Name"
              value={username.Lname}
              onChange={(e) =>
                setUsername({ ...username, Lname: e.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-sm-6">
            <label className="form-label text-white">Date of Birth</label>
            <div className="row">
              <div className="col-4">
                <input
                  type="text"
                  className="form-control custom-input"
                  name="dob.day"
                  placeholder="DD"
                  value={formik.values.dob.day}
                  onChange={(e) => {
                    const error = validateDateOfBirth(e.target.value, "day");
                    if (!error) formik.handleChange(e);
                  }}
                />
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="form-control custom-input"
                  name="dob.month"
                  placeholder="MM"
                  value={formik.values.dob.month}
                  onChange={(e) => {
                    const error = validateDateOfBirth(e.target.value, "month");
                    if (!error) formik.handleChange(e);
                  }}
                />
              </div>
              <div className="col-4">
                <input
                  type="text"
                  className="form-control custom-input"
                  name="dob.year"
                  placeholder="YYYY"
                  value={formik.values.dob.year}
                  onChange={(e) => {
                    const error = validateDateOfBirth(e.target.value, "year");
                    if (!error) formik.handleChange(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mb-3 col-sm-6">
            <label className="form-label text-white">Gender</label>
            <div className="input-group custom-input-group">
              <input
                type="text"
                className="custom-input form-control "
                name="gender"
                value={formik.values.gender || "Select Gender"}
              />
              <button
                className="btn btn-outline-secondary dropdown-toggle bg-282828"
                type="button"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              ></button>
              {dropdownOpen && (
                <ul className="dropdown-menu dropdown-menu-end show w-100">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleGenderChange("Male")}
                    >
                      Male
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleGenderChange("Female")}
                    >
                      Female
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleGenderChange("Other")}
                    >
                      Other
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-sm-6">
            <label className="form-label text-white">Phone Number</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control custom-input"
                value={formik.values.number}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); // Trim spaces
                  const error = validatePhoneNumber(trimmedValue);
                  if (!error) {
                    formik.setFieldValue("number", trimmedValue);
                  }
                }}
                name="number"
              />
            </div>
          </div>
          <div className="mb-3 col-sm-6">
            <label className="form-label text-white">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control custom-input"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <div className="Change-Password-btn row justify-content-center">
            <div className="col-auto">
              <button type="submit">Save Changes</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
};
