
import React, { useState, useEffect, useRef } from "react";
import { MatchData } from "../../shared/mock.data";
import "./Match.css";

const AllMatches = () => {
  const videoData = MatchData;
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [openSaveDropdownId, setOpenSaveDropdownId] = useState(null);
  const [checkedState, setCheckedState] = useState({});
  const dropdownRefs = useRef({});

  const toggleDropdown = (id) => {
    if (openDropdownId === id) {
      setOpenDropdownId(null);
    } else {
      setOpenDropdownId(id);
      setOpenSaveDropdownId(null);
    }
  };

  const toggleSaveDropdown = (id, e) => {
    e.stopPropagation();
    if (openSaveDropdownId === id) {
      setOpenSaveDropdownId(null);
    } else {
      setOpenSaveDropdownId(id);
    }
  };

  const handleCheckboxChange = (itemId, playlist, e) => {
    e.stopPropagation();
    setCheckedState((prevState) => ({
      ...prevState,
      [itemId]: {
        ...prevState[itemId],
        [playlist]: !prevState[itemId]?.[playlist],
      },
    }));
  };

  const handleClickOutside = (event) => {
    if (!Object.values(dropdownRefs.current).some(ref => ref && ref.contains(event.target))) {
      setOpenDropdownId(null);
      setOpenSaveDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const role = JSON.parse(localStorage.getItem('loginData')) 
  const rolename= role?.role 

  return (
    <div>
        <div className="home_head_wrap">
                  <h6> Matches</h6> 
                </div> 
      <div className="VideoSuggesions_main_wrap">
        <div className="For_Videos">
          <div className="container">
            <div className="row">
              {videoData.map((item) => (
                <div key={item.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <a href={`/${rolename}/video-save`}>
                      <div className="card_img_wrap">
                        <img src={item.videocover} alt="" className="img-fluid" />
                      </div>
                      </a>
                      <div className="d-flex justify-content-between">
                        <div className="match_info_subtitles">
                          <div className="title_wrap d-flex gap-10px">
                            <img src={item.vdotitledp} alt="" className="img-fluid" />
                            <div>
                              <h5>{item.title}</h5>
                              <h6>{item.profession}</h6>
                              <p>
                                Today <span></span>
                                {item.viewsonvid}

                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => toggleDropdown(item.id)}
                          style={{ position: "relative" }}
                          ref={el => dropdownRefs.current[item.id] = el}
                        >
                          <div className="video-icon">
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </div>
                          {openDropdownId === item.id && (
                            <div className="dropdown-menu show">
                              <a className="dropdown-item" href="#" onClick={(e) => toggleSaveDropdown(item.id, e)}>
                                <i className="fa-solid fa-bookmark"></i>
                                <span className="ml-3">Save to Playlist</span>
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="fa-solid fa-download"></i>
                                <span className="ml-3">Download</span>
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="fa-solid fa-share"></i>
                                <span className="ml-3">Share</span>
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                <span className="ml-3">Report</span>
                              </a>
                              {openSaveDropdownId === item.id && (
                                <div className="dropdown-for-save dropdown_for_save show" onClick={(e) => e.stopPropagation()}>
                                  <p className="dropdown-header">Save to..</p>
                                  <div className="padding-l-r-18">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkedState[item.id]?.watchLater || false}
                                        onChange={(e) => handleCheckboxChange(item.id, "watchLater", e)}
                                        id={`watchLater-${item.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`watchLater-${item.id}`}
                                      >
                                        Watch Later
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkedState[item.id]?.tutorial || false}
                                        onChange={(e) => handleCheckboxChange(item.id, "tutorial", e)}
                                        id={`tutorial-${item.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`tutorial-${item.id}`}
                                      >
                                        Tutorial
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkedState[item.id]?.workout || false}
                                        onChange={(e) => handleCheckboxChange(item.id, "workout", e)}
                                        id={`workout-${item.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`workout-${item.id}`}
                                      >
                                        Workout
                                      </label>
                                    </div>
                                  </div>
                                  <div className="dropdown-footer">
                                    <i className="fa-solid fa-plus"></i>
                                    <span>Create new playlist</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllMatches;

