
import Home from "../pages/Home";
import AllMatches from "../pages/Matches/AllMatches";
import Matches from "../pages/Matches/Matches";
import VideoSave from "../pages/Matches/Video_save";
import Negotiation from "../pages/Negotiation/Negotiation";
import AddnewPlayer from "../pages/Players/AddnewPlayer";
import PlayerProfile from "../pages/Players/PlayerProfile";
import MyReels from "../pages/Profile/MyReels";
import PlaylistView from "../pages/Profile/PlaylistView";
import Profile from "../pages/Profile/Profile";
import Reeldetail from "../pages/Profile/Reeldetail";
import Billing from "../pages/Setting/Billing";
import ChangeSubscription from "../pages/Setting/ChangeSubscription";
import Settings from "../pages/Setting/Settings";
import Reels from "../pages/homeComponents/Reels";
import Offers from "../pages/homeComponents/offers";
import Layout from "./defaultLayout/Layout";
  
 export const Professional_club = [
    {
      path: "/professional_club",
      element: <Layout/>,
      children: [
        {path: "",element: <Home/>,},
        {path: "offers",element: <Offers/>,},
        { path: 'matches', element: <Matches /> },
        { path: 'reels', element: <Reels /> },
        { path: 'player', element: <AddnewPlayer /> },
        { path: 'playerprofile', element: <PlayerProfile /> },
        { path: 'allmatches', element: <AllMatches /> },
        { path: 'video-save', element: <VideoSave /> },
        { path: 'Negotiation', element: <Negotiation /> },
        {path: 'profile',element:<Profile />},
          { path: 'myreels', element: <MyReels /> },
        { path: 'playlistView', element: <PlaylistView /> },
        { path: 'reeldetail', element: <Reeldetail /> },
        { path: 'setting', element: <Settings /> },
        { path: 'Changesubs', element: <ChangeSubscription /> },
        { path: 'Billing', element: <Billing /> },
  
      ],
    
    },

  ];