
import Landing from "../components/Landing/Landing";


 export const Indexrouter = [
    {
      path: "/",
      children: [       
        { path: '/', element: <Landing /> },

      ],
    
    },
   
  ];


  
  
//       ],
    
//     },

//   ];