import React from "react";
import CreateVideo from "./homeComponents/CreateVideo";
import PlayersList from "./homeComponents/playerslist";
import './sidebar/sidebar.css';
import VideoSuggestion from "./homeComponents/VideoSuggestion";

const Home = () => {

  return (
    <>
      <CreateVideo />
      <PlayersList />
      <VideoSuggestion />
    </>
  );
};

export default Home;
