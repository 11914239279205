import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import io from "socket.io-client";
import { fetch_friend_Chat, fetch_friend_request, fetch_getFriends, fetch_remove_chat, remove_message } from "../api/ChatApi";

const SOCKET_SERVER_URL = "http://38.242.230.126:1770";

let socket = null;

const initialState = {
  friendRequestUpdates: [],
  friendRequests: [],
  friends:[],
  Chat: [],
  onlineStack: [],
  roomADD: "",
  room: "",
  loading: false,
  error: null,
};

const AsyncFunctionThunk = (name, apiFunction) => {
  return createAsyncThunk(`User/${name}`, async (data, { rejectWithValue }) => {
    try {
      const response = await apiFunction(data);

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue({ error: error.message });
      throw error;
    }
  });
};

const SocketFunctionThunk = (name, socketEvent, data = {}) => {
  return createAsyncThunk(
    `socket/${name}`,
    async (data, { rejectWithValue }) => {
      try {
        socket.emit(socketEvent, data);

        // The assumption here is that the server sends back a confirmation or error
        return data;
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data);
        }
        return rejectWithValue({ error: error.message });
      }
    }
  );
};

export const initializeSocket = createAsyncThunk(
  "socket/initializeSocket",
  async (userId, { dispatch }) => {
    if (socket) {
      socket.disconnect();
    }
console.log('connecting .......',userId);

    socket = io(SOCKET_SERVER_URL, {
      query: { id: userId },
    });

    socket.emit("set-user", userId);
    socket.on("friendRequestReceived", (data) => {
      dispatch(friendRequestReceived(data));
    });

    socket.on("friendRequestUpdated", (data) => {
      dispatch(friendRequestUpdated(data));
    });

    socket.on("set-room", (data) => {
        console.log(data,'set romm done');
        
      dispatch(RoomUpdated(data));
    });

    socket.on("chat-msg", (data) => {
        console.log('rec',data);
        
      dispatch(ChatUpdated(data));
    });
    socket.on("onlineStack", (data) => {
      console.log(data, "onlineStack");
      dispatch(OnlineStackUpdated(data.reverse()));

    });

    return { userId };
  }
);

export const sendFriendRequest = SocketFunctionThunk(
  "sendFriendRequest",
  "sendFriendRequest"
);
export const updateFriendRequestStatus = SocketFunctionThunk(
  "updateFriendRequestStatus",
  "updateFriendRequestStatus"
);
export const setRoom = SocketFunctionThunk("set-room", "set-room");
export const chatMsg = SocketFunctionThunk("chat-msg", "chat-msg");
export const fetch_friend_requests = AsyncFunctionThunk("fetch_friend_request",fetch_friend_request);
export const fetch_friend_Chats = AsyncFunctionThunk("fetch_friend_Chats",fetch_friend_Chat);
export const fetch_getFriendss = AsyncFunctionThunk("fetch_getFriendss",fetch_getFriends);
export const remove_messages = AsyncFunctionThunk("remove_messages",remove_message);

export const fetch_remove_chats = AsyncFunctionThunk("fetch_remove_chats",fetch_remove_chat);
const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    friendRequestReceived: (state, action) => {
      state.friendRequests.push(action.payload);
    },
    friendRequestUpdated: (state, action) => {
      state.friendRequestUpdates.push(action.payload);
    },
    ChatUpdated: (state, action) => {
      state.Chat = [...state.Chat, action.payload ];
    },
    RoomUpdated: (state, action) => {
      state.roomADD = action.payload;
    },
    OnlineStackUpdated: (state, action) => {
      state.onlineStack = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeSocket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(initializeSocket.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(initializeSocket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetch_friend_requests.fulfilled, (state, action) => {
        state.friendRequests = action.payload;
        state.loading = false;
      })
      .addCase(fetch_friend_requests.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetch_friend_requests.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(setRoom.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(setRoom.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(chatMsg.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(chatMsg.pending, (state) => {
        state.loading = true;
      })
      .addCase(chatMsg.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(fetch_friend_Chats.fulfilled, (state, action) => {
        state.Chat = action.payload;
        state.loading = false;
      })
      .addCase(fetch_friend_Chats.pending, (state) => {
        state.Chat = [];
        state.loading = true;
      })
      .addCase(fetch_friend_Chats.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(fetch_remove_chats.fulfilled, (state, action) => {

        state.loading = false;
      })
      .addCase(fetch_remove_chats.pending, (state) => {

        state.loading = true;
      })
      .addCase(fetch_remove_chats.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(remove_messages.fulfilled, (state, action) => {

        state.loading = false;
      })
      .addCase(remove_messages.pending, (state) => {

        state.loading = true;
      })
      .addCase(remove_messages.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(fetch_getFriendss.fulfilled, (state, action) => {
        state.friends = action.payload
        state.loading = false;
      })
      .addCase(fetch_getFriendss.pending, (state) => {

        state.loading = true;
      })
      .addCase(fetch_getFriendss.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      });
  },
});

export const {
  friendRequestReceived,
  friendRequestUpdated,
  ChatUpdated,
  OnlineStackUpdated,
  RoomUpdated,
} = socketSlice.actions;
export default socketSlice.reducer;
