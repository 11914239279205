
import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import signUp_img from '../../../assets/Rectangle 4337.png';
import { useDispatch } from 'react-redux';
import { createUserSlice } from '../../../redux/slice/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const initialValues = {
    Name: '',
    email: '',
    number: '',
    password: '',
  };

  const validationSchema = Yup.object({
    Name: Yup.string()
      .required('Name is required'),

    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),

    number: Yup.string()
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .required('Phone number is required'),

    password: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .matches(/@/, 'Password must contain at least one "@" symbol')
      .required('Password is required'),
  });

   


    const handleSubmit = async (values, { setSubmitting }) => {
    
      try {
        const res = await dispatch(createUserSlice({ ...values, number: values.number.toString() }));
    
        const { token, user } = res.payload || {}; 
    
        if (token && user) {
          toast.success('Registration successful!');
    
          const loginData = { token:token,_id:user.userId, ...user };
          localStorage.setItem('loginData', JSON.stringify(loginData));
    
          navigate("/SignUp-roles");
        } else {
          toast.error('Registration failed. Please try again.');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Error submitting form. Please try again.');
      } finally {
        setSubmitting(false);
      }
    };
    
   
    



  return (
    <div className="row">
      <div className="col-md-12 col-lg-6 justify-content-center mb-3">
        <div className="signup justify-content-center width-400">
          <div className="signUp-header-text text-center justify-content-center">
            <span>Enter your details to Sign Up</span>
            <hr className="mx-auto w-50" />
          </div>
          <div className="row justify-content-center">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                  <div className="mb-4">
                    <Field type="text" name="Name" className="signUp-body-input p-4" placeholder="Name *" />
                    <ErrorMessage name="Name" component="div" className="text-danger" />
                  </div>

                  <div className="mb-4">
                    <Field type="email" name="email" className="signUp-body-input p-4" placeholder="Email ID *" />
                    <ErrorMessage name="email" component="div" className="text-danger" />
                  </div>

                  <div className="mb-4">
                    <Field
                      type="text"
                      name="number"
                      className="signUp-body-input p-4"
                      placeholder="Number *"
                      value={values.number}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                        setFieldValue('number', value);
                      }}
                    />
                    <ErrorMessage name="number" component="div" className="text-danger" />
                  </div>

                  <div className="mb-4">
                    <Field type="password" name="password" className="signUp-body-input p-4" placeholder="Password *" />
                    <ErrorMessage name="password" component="div" className="text-danger" />
                  </div>

                  <div className="mb-4">
                    <input type="checkbox" className="form-check-input" />
                    <label className="form-check-label text-white">Remember my details</label>
                  </div>

                  <div className="mb-4">
                    <input type="checkbox" className="form-check-input" />
                    <label className="form-check-label text-white" htmlFor="acceptTerms">
                      I accept all <span className="text-danger">Terms and Conditions *</span>
                    </label>
                    {/* <ErrorMessage name="acceptTerms" component="div" className="text-danger" /> */}
                  </div>

                  <div className="signUp-button">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Sign Up'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-lg-6 position-relative">
        <div className="signUp-img">
          <img src={signUp_img} alt="Sign Up" />
        </div>
        <div className="signUp-img-text justify-content-center">
          <div className="m-3">
            <div className="d-flex align-items-center signUp-text-1">
              <i className="fa-solid fa-period"></i>
              <span className="ml-2">Lorem ipsum dolor sit amet consectetur.</span>
            </div>
            <div className="d-flex align-items-center signUp-text-1">
              <i className="fa-solid fa-period"></i>
              <span className="ml-2">Condimentum integer quis eget vehicula nunc sit tellus.</span>
            </div>
            <div className="d-flex align-items-center signUp-text-1">
              <i className="fa-solid fa-period"></i>
              <span className="ml-2">Id lorem amet ac nullam consequat semper amet magna pulvinar.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
