
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { gatAllPlayerApi, SearchPlayerApi } from '../api/user';

const loginData = JSON.parse(localStorage.getItem('loginData'));
const UserId = loginData?._id;

// Thunk for fetching all players
export const getAllPlayerSlice = createAsyncThunk(
  'players/getAll',
  async (params, { rejectWithValue }) => {
    try {
      const response = await gatAllPlayerApi(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.data || { message: 'An unknown error occurred' });
    }
  }
);

// Thunk for searching players
export const searchPlayerSlice = createAsyncThunk(
  'players/search',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SearchPlayerApi(params);
      return response;
    } catch (error) {
      return rejectWithValue(error.data || { message: 'An unknown error occurred' });
    }
  }
);

// Profile slice
const playersSlice = createSlice({
  name: 'players',
  initialState: {
    loading: false,
    players: [],
    filterplayers: [],
    error: null, 
  },
  reducers: {},
  extraReducers: (builder) => {
    // all players
    builder
      .addCase(getAllPlayerSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllPlayerSlice.fulfilled, (state, action) => {
        state.loading = false;
        const allPlayers = action.payload.data;
        const filteredPlayers = allPlayers.filter(player => player.userId._id !== UserId);
        state.players = filteredPlayers;
      })
      .addCase(getAllPlayerSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch players';
      })
      //  search players
      .addCase(searchPlayerSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchPlayerSlice.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload.data);
        
        state.filterplayers = action.payload.data?.data; 
      })
      .addCase(searchPlayerSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to search players';
      });
  },
});

export default playersSlice.reducer;
