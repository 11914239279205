import React from "react";
import ArrowRight from "../../assets/arrow_right.svg";
import ArrowUp from "../../assets/Arrow_up.svg";
import ReelsIcon from "../../assets/reels-icon.svg";
import ClubIconA from "../../assets/ClubIcona.svg";
import ClubIconB from "../../assets/ClubIconb.svg";
import ClubIconC from "../../assets/ClubIconc.svg";
import ClubIconD from "../../assets/ClubIconD.svg";
import ClubIconE from "../../assets/ClubIcone.svg";
import ClubIconF from "../../assets/ClubIconf.svg";

import PositionMan from "../../assets/gis_position-man.svg";
import BasketballJersey from "../../assets/game-icons_basketball-jersey.svg";
import { profilereelsData } from "../../shared/mock.data";
import "./PlayerProfile.css";
import { Link, useLocation } from "react-router-dom";

const PlayerProfile = () => {
  const reels = profilereelsData;
  const role = JSON.parse(localStorage.getItem('loginData')) 
  const rolename= role?.role 
const location = useLocation();


  const { player } = location.state || {}; 

  if (!player) {
    return <p>No player data available. Please select a player from the list.</p>;
  }


  
  return (
    <>
      <div className="container_fluid">
        <div className="top_heading">
          <h1>Players</h1>
          <div className="players_top_tab_view d-flex align-items-center gap-3">
            <h6>Players</h6>
            <img src={ArrowRight} alt="" />
            <span>Player Details</span>
          </div>
        </div>
        <div className="row align-items-center mt-52px">
          <div className="col-xxl-4 mb-5">
            <div className="Profile_left_wrap">
              <div className="Players_dp_wrap">
                <img src={`https://mosis-backend.cradle.services${player.face_picture}`} alt="" style={{    height: '265px', margin: '40px'}} />
              </div>
              <div className="players_details">
                <div className="Plyers_name_btn d-flex align-items-center justify-content-between">
                  <h5>Alfonso Bergson</h5>
                  <button>Under Negotiation</button>
                </div>
                <div className="d-flex player-dtl ">
                  <p>Professional Player</p>
                  <span>
                    <i className="fa-solid fa-period"></i>
                  </span>
                  <img src={BasketballJersey} alt="" />
                  <p>{player.experience[0].tshirtNumber}</p>
                  <span>
                    <i className="fa-solid fa-period"></i>
                  </span>
                  <img src={PositionMan} alt="" />
                  <p>{player.experience[0].position}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-8">
            <div className="Profile_right_wrap">
              <div className="overview_etc justify-content-between">
                <h4 className="heading-with-dot">Overview:</h4>
                <div className="overview_etc_right d-flex justify-content-between gap-3">
                  <p>2021- Present:</p>
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Imperdiet urna
                    aliquet pellentesque vulputate nisi.{" "}
                  </p>
                </div>
              </div>
              <div className="overview_etc justify-content-between">
                <h4 className="heading-with-dot">Height:</h4>
                <div className="overview_etc_right d-flex justify-content-between gap-3">
                  <p>{player.currentHeight}</p>
                </div>
              </div>
              <div className="overview_etc justify-content-between">
                <h4 className="heading-with-dot">Weight:</h4>
                <div className="overview_etc_right d-flex justify-content-between gap-3">
                  <p>{player.currentWeight}</p>
                </div>
              </div>
              <div className="overview_etc justify-content-between">
                <h4 className="heading-with-dot">Profile Visits:</h4>
                <div className="overview_etc_right d-flex justify-content-between gap-3">
                  <p>1000</p>
                  <span>
                    21% <img src={ArrowUp} alt="" />
                  </span>
                </div>
              </div>
              <div className="overview_etc justify-content-between">
                <h4 className="heading-with-dot">Clubs:</h4>
                <div className="overview_etc_right d-flex justify-content-between gap-3">
                  <img src={ClubIconA} alt="" />
                  <img src={ClubIconB} alt="" />
                  <img src={ClubIconC} alt="" />
                  <img src={ClubIconD} alt="" />
                  <img src={ClubIconE} alt="" />
                  <img src={ClubIconF} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="VideoSuggesions_main_wrap">
          <div className="for_reels mt-52px">
            <div className="reels_top_wrap d-flex gap-12px align-items-center">
            <img src={ReelsIcon} alt="" />
            <h6>Reels</h6>
          </div>
            <div class="container">
              <div class="row">
                {reels.map((item) => (
                  <div
                    key={item.id}
                    className="col-12 col-md-6 col-lg-4 col-xl-2 mb-4"
                  >
                    <div className="card">
                      <div className="card-body">
                        <div className="card_img_wrap">
                          <div className="video-player">
                            {/* <video controls>
                            <source src={item.src} type="video/mp4" />
                          </video> */}
                            <Link to={`/${rolename}/reels`}>
                              <img src={item.reelcover} alt="" />
                            </Link>
                          </div>
                        </div>
                        <div className="match_info_subtitles">
                          <h5>{item.title}</h5>
                          <p>
                            Today <span></span>
                            {item.viewsonreel}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
};

export default PlayerProfile;


