import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SearchPlayer = ({ filterplayer }) => {
  const navigate = useNavigate();
  
  const player = useSelector((state) => state.players);
  const AllPlayer = Array.isArray(player) ? player : player.players || [];

  const playersToDisplay = filterplayer && filterplayer.length > 0 ? filterplayer : AllPlayer;

  const role = JSON.parse(localStorage.getItem('loginData'));
  const rolename = role?.role;

  return (
    <div className="players_list_main_wrap">
      <div className="container">
        <div className="row flex_wrap_wrap">
          {playersToDisplay && playersToDisplay.map((player) => (
            <div key={player._id} className="width-202px col-md-12 mb-4">
              <div className="card custom-card">
                <div className="card-body">
                  <img src={`https://mosis-backend.cradle.services${player.face_picture}`} alt={player.Name} />
                  <h6>{player.Name}</h6>
                  <button
                    onClick={() =>
                      navigate(`/${rolename}/playerprofile`, {
                        state: { player },
                      })
                    }
                  >
                    Check
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchPlayer;
