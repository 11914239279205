import React, { useState } from 'react';
import { Formik, Form  } from 'formik';
import * as yup from 'yup';
import { useDispatch  } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ClubDetail from '../ProfessionalClub/ClubDetail';
import ClubContacts from '../ProfessionalClub/ClubContacts';
import AddTeam from '../ProfessionalClub/AddTeam';
import { creatClubSlice } from '../../../../redux/slice/Club';
import { updateUserApi } from '../../../../redux/api/user';

const validationSchemas = {
    1: yup.object().shape({
        Name: yup.string().required('Club Name is required'),
        cnpj: yup.string().required('CNPJ ID is required'),
        city: yup.string().required('city  is required'),
        state: yup.string().required('state is required'),
        country: yup.string().required('country is required'),
        websiteLink: yup.string().required('Website link is required'),       
        seriesId: yup.string().required('Series is required'),
    }),
    2: yup.object().shape({
        // Add validation schema for tab 2
    }),
    3: yup.object().shape({
        // Add validation schema for tab 3
    }),
};

const loginData = localStorage.getItem('loginData');

let getuserData = {};
if (loginData && loginData !== 'undefined') {
  try {
    getuserData = JSON.parse(loginData);
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }
}




const userToken =getuserData?.token
const userid= getuserData?._id ? getuserData?._id : getuserData?.userId


const initialValues = {
    userId: '',
  clubTypeId: '66e82833642dc9c8432dded0',
  seriesId: '66b1d078eda01d2bd419b460',
  categoriesId: '66b1b9287528470934117c5f',
  Name: '',
  cnpj: '',
  aboutClub: '',
  websiteLink: '',
  established_date: "",
  city: '',
  state: '',
  country: '',
  contact_persons: [
    {
      name: '',
      email: '',
      position: '',
      number:''
    }
  ]
};

const Club = () => {
    const [tab, setTab] = useState(1);
    const dispatch = useDispatch()   
    const navigate = useNavigate()   
    const UserRole = localStorage.getItem('UserRole');

    const handleNextClick = async(formik) => {
        try {
            await validationSchemas[tab].validate(formik.values, { abortEarly: false });
            if (tab < 3) {
                setTab(tab + 1); 
            }
        } catch (err) {
            console.error('Validation Error:', err);
            if (err.inner && Array.isArray(err.inner)) {
                const errors = err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {});
                formik.setErrors(errors);
                formik.setTouched(Object.keys(errors).reduce((acc, curr) => {
                    acc[curr] = true;
                    return acc;
                }, {}));
            } else {
                toast.error('An unexpected validation error occurred.');
            }
        }
    };

   


    const onSubmit = async (values, { setSubmitting }) => {
      if (values.established_date) {
          const { day, month, year } = values.established_date;
          values.established_date = new Date(year, month - 1, day).toISOString().split('T')[0]; 
      }
  
      const newVal = {
          ...values,
          userId: userid,
          role: UserRole,
          token: userToken,
      };
      
  
      try {
          const res = await dispatch(creatClubSlice(newVal));
  
          
          if (res.payload && res.payload.isSuccess) {
              const userRoleResponse = await updateUserApi({ id: userid, token: userToken, data: { role: UserRole } });
                          
              if (userRoleResponse?.data?.role) {
                  localStorage.setItem('loginData', JSON.stringify(newVal));
                  navigate("/professional_club");
                  toast.success('Club Registration successful!');
              } else {
                  throw new Error('Failed to update user role');
              }
          } else {
              toast.error('Club Registration failed!');
          }
      } catch (error) {
          console.error("Error during registration:", error);
          toast.error(`An error occurred during registration: ${error.message}`);
      } finally {
          setSubmitting(false);
      }
  };
  
    



    return (
        <div className=''>
            <div className='progressbar d-flex flex-column align-items-center'>
                <div className='text-center'>
                    <span className='progressbar-header'>You are almost there!</span>
                </div>

                <div className='mt-3  align-items-center'>
                    <div className='d-flex align-items-center'>
                        <div className='progress'>
                            <div className='progress-bar bg-success' style={{ width: `${(tab / 3) * 100}%` }}></div>
                        </div>
                        <span className='ml-4'>{Math.round((tab / 3) * 100)}%</span>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchemas[tab]}
                        onSubmit={onSubmit}
                    >
                        {(formik) => (
                            <Form className='mt-4'>
                                {tab === 1 && <ClubDetail setFieldValue={formik.setFieldValue} />}
                                {tab === 2 && <ClubContacts setFieldValue={formik.setFieldValue} />}
                                {tab === 3 && <AddTeam setFieldValue={formik.setFieldValue} />}
                                {tab > 1 && (
                  <div className="previouspage-arrow">
                    <button
                      type="button"
                      className="nextpage-arrow-button"
                      onClick={() => setTab(tab - 1)}
                    >
                      <i className="fa-solid fa-less-than"></i>
                    </button>
                  </div>
                )} 
                {tab < 5 && (
                  <div className="nextpage-arrow">
                    <button
                      type="button"
                      className="nextpage-arrow-button"
                      onClick={() => handleNextClick(formik)}
                    >
                      <i className="fa-solid fa-greater-than"></i>
                    </button>
                  </div>
                )}

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <button type="button" className="skip-button">
                    Skip
                  </button>
                  <button
                    type="button"
                    className="next-button ml-4"
                    onClick={() =>
                      tab < 3 ? handleNextClick(formik) : formik.handleSubmit()
                    }
                  >
                    {tab < 3 ? "Next" : "Submit"}
                  </button>
                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            <div className='nextpage-arrow'>
                <button className='nextpage-arrow-button'
                onClick={() => setTab(tab - 1)}
                disabled={tab === 1}
                 >
                    <i className='fa-solid fa-greater-than'></i>
                </button>
            </div>
        </div>
    );
};

export default Club;
