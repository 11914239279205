import React, { useEffect, useState } from "react";
import Profile from "../../assets/unsplash_6anudmpILw4.png";
import "./Settings.css";
import mastercard from "../../assets/MasterCard_Logo 3.png";
import visa from "../../assets/Visa_Inc._logo 3.png";
import PolygonA from "../../assets/PolygonA.svg";
import PolygonB from "../../assets/PolygonB.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import { General } from "./General";
import Password from "./Password";
import { getPlayerSlice } from "../../redux/slice/GetPlayer";
import { uploadImage } from "../../redux/slice/PlayerImageUpload";
import Subscription from "./Subscription";

const Settings = () => {
  const dispatch = useDispatch();

  const [selectedSection, setSelectedSection] = useState("General");
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(Profile);

  const [role, setRole] = useState({}); 

  useEffect(() => {
    const fetchRoleFromLocalStorage = () => {
      const loginData = localStorage.getItem("loginData");

      if (loginData && loginData !== "undefined") {
        try {
          const parsedRole = JSON.parse(loginData);
          setRole(parsedRole); 
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      } else {
        console.warn("loginData is not available or is undefined");
      }
    };

    fetchRoleFromLocalStorage(); 
  }, []);
  const rolename = role?.role;
  const userId = role?._id;
  const userToken = role?.token;

  



  const roleObj = [
    {
      get: "player",
      value: "Player",
    },
    {
      get: "agent",
      value: "Agent",
    },
    {
      get: "soccer_school",
      value: "SoccerSchool",
    },
    {
      get: "professional_club",
      value: "Club",
    },
    {
      get: "amateur_club",
      value: "Club",
    },
  ];

  const { player, loading, error } = useSelector((state) => state.profile);

  useEffect(() => {
    const getProfilePicture = async () => {
      const endPoint = roleObj.filter((i) => i.get === rolename);
      try {
        const res = await dispatch(getPlayerSlice({userId: userId,token:userToken,endPoint: endPoint[0]}));
        if (res.payload.isSuccess && res.payload.data[0]?.face_picture) {
          setPreviewImage(res.payload.data[0].face_picture); // Set profile picture
          
        }
      } catch (error) {
        console.error("Failed to fetch profile picture:", error);
      }
    };

    if (role != {}) {
      getProfilePicture();
    }
  }, [role, dispatch]);

  const PlayerId = player?._id;

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
      };
      reader.readAsDataURL(file);

      // Auto-upload the selected image
      const formData = new FormData();
      formData.append("profilePicture", file);

      try {
        const endPoint = roleObj.filter((i) => i.get === rolename);

        const res = await dispatch(uploadImage({ id: PlayerId, token: userToken, formData,endPoint: endPoint[0]}));

        console.log("Response:", res);

        if (res.payload) {
          toast.success("Profile picture uploaded successfully");
        } else {
          toast.error("Failed to upload profile picture");
        }
      } catch (error) {
        toast.error("Failed to upload profile picture!");
      }
    }
  };

  // console.log('previewImagepreviewImagepreviewImage',previewImage);

  return (
    <div className="Profile_settings_main_wrap">
      <h2>Settings</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="setting-img">
            <img
              src={previewImage ? `https://mosis-backend.cradle.services${previewImage}`: Profile }
              alt="Profile"
            />
            <label htmlFor="file-upload" className="file-upload-label">
              <span className="plus-icon">+</span>
              <input
                type="file"
                id="file-upload"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the actual file input
              />
            </label>
          </div>
          <div className="setting-btn">
            <button
              className={`general col-12 ${
                selectedSection === "General" ? "active" : "inactive"
              }`}
              onClick={() => setSelectedSection("General")}
            >
              <span>General</span>
            </button>
            <button
              className={`subscription col-12 ${
                selectedSection === "Password" ? "active" : "inactive"
              }`}
              onClick={() => setSelectedSection("Password")}
            >
              <span>Password Change</span>
            </button>

            <button
              className={`subscription col-12 ${
                selectedSection === "Subscription" ? "active" : "inactive"
              }`}
              onClick={() => setSelectedSection("Subscription")}
            >
              <span>Subscription</span>
            </button>
            <button
              className={`subscription col-12 ${
                selectedSection === "Invoice" ? "active" : "inactive"
              }`}
              onClick={() => setSelectedSection("Invoice")}
            >
              <span>Invoice</span>
            </button>
          </div>
        </div>
        <div className="col-md-8 Form_for_user">
          {selectedSection === "General" && (
            <>
              <General />
            </>
          )}
          {selectedSection === "Password" && (
            <>
              <Password />
            </>
          )}

          {selectedSection === "Subscription" && (
            <>
            <Subscription />
            </>
          )}
          {selectedSection === "Invoice" && (
            <>
              <div className="Card-Details">
                <h2 className="card_detail_h2">Card Details</h2>
                <div className="d-flex align-items-center card_payment justify-content-between">
                  <div className="d-flex text-white ">
                    <p>Card Number:</p>
                    <span className="ml-2 text-color">*********235</span>
                  </div>
                  <div className="text-end ">
                    <Link to="" className="text-white">
                      Change Billing Method
                    </Link>
                  </div>
                </div>
                <div className="Credit-card">
                  <div className="Credit-card-header d-flex">
                    <div className="Credit-card-header-text">
                      <h6>Credit/Debit card</h6>
                    </div>
                    <div>
                      <span>
                        <img src={mastercard} alt="" />
                      </span>
                      <span className="ml-2">
                        <img src={visa} alt="" />
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className="mb-3 col-sm-6">
                        <label
                          htmlFor="firstName"
                          className="form-label text-white"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          className="form-control custom-input"
                          placeholder=""
                        />
                      </div>
                      <div className="mb-3 col-sm-6">
                        <label
                          htmlFor="lastName"
                          className="form-label text-white"
                        >
                          Card Number
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          className="form-control custom-input"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-3">
                      <label
                        htmlFor="firstName"
                        className="form-label text-white"
                      >
                        Expiry
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        className="form-control custom-input"
                        placeholder=""
                      />
                    </div>
                    <div className="mb-3 col-3">
                      <label
                        htmlFor="lastName"
                        className="form-label text-white"
                      >
                        CVC/CVV
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        className="form-control custom-input"
                        placeholder=""
                      />
                    </div>
                    <div className="col-sm-6 Credit-card-button">
                      <button>Save</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Invoices">
                <div className="Credit-card-header-text">
                  <h2>Invoices</h2>
                </div>

                <div className="Invoices-div ">
                  <div className="Invoices-header d-flex justify-content-between align-items-center m-2 pt-2">
                    <h4>Show</h4>
                    <div className="d-flex align-items-center Invoices-header-search-bar">
                      <div className="input-group">
                        <div className="input-group-append">
                          <span className="input-group-text rounded-left">
                            <i className="fa-solid fa-magnifying-glass"></i>
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control rounded-right"
                          placeholder="Search"
                          aria-label="Search"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between text-white invoice-table-header">
                    <div className="col-2">
                      <h6>Sr. No</h6>{" "}
                    </div>
                    <div className="col-2">
                      <h6>Method</h6>
                    </div>
                    <div className="col-4 d-flex align-items-center">
                      <h6>
                        {" "}
                        Service Period{" "}
                        <i className="fa-regular fa-calendar-days ml-2"></i>
                      </h6>
                    </div>
                    <div className="col-2">
                      <h6>Total</h6>
                    </div>
                    <div className="col-1"></div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center text-white pb-2 pt-2 table_data_wrap bd-1 custom-border-width">
                    <div className="col-2">1</div>
                    <div className="col-2">Credit Card</div>
                    <div className="col-4">dd/mm/yyyy - dd/mm/yyyy</div>
                    <div className="col-2">$0</div>
                    <div className="col-1">
                      <i className="fa-solid fa-download"></i>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center text-white pb-2 pt-2 table_data_wrap custom-border-width">
                    <div className="col-2">2</div>
                    <div className="col-2">Credit Card</div>
                    <div className="col-4">dd/mm/yyyy - dd/mm/yyyy</div>
                    <div className="col-2">$0</div>
                    <div className="col-1">
                      <i className="fa-solid fa-download"></i>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center text-white pb-2 pt-2 table_data_wrap custom-border-width">
                    <div className="col-2">3</div>
                    <div className="col-2">Credit Card</div>
                    <div className="col-4">dd/mm/yyyy - dd/mm/yyyy</div>
                    <div className="col-2">$0</div>
                    <div className="col-1">
                      <i className="fa-solid fa-download"></i>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center text-white pb-2 pt-2 table_data_wrap">
                    <div className="col-2">4</div>
                    <div className="col-2">Credit Card</div>
                    <div className="col-4">dd/mm/yyyy - dd/mm/yyyy</div>
                    <div className="col-2">$0</div>
                    <div className="col-1">
                      <i className="fa-solid fa-download"></i>
                    </div>
                  </div>
                  <div className="table_footer_pagination ">
                    <div className="container">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination pagination-custom justify-content-center mb-0">
                          {/* Previous Page Link */}
                          <li className="page-item disabled">
                            <span className="page-link">
                              <img src={PolygonA} alt="Move back" />
                            </span>
                          </li>

                          {/* Page Numbers */}
                          <li className="page-item">
                            <a className="page-link active" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>

                          {/* Dots */}
                          <li className="page-item pagination-dots">
                            <span className="page-link">...</span>
                          </li>

                          {/* Page Numbers */}
                          <li className="page-item">
                            <a className="page-link" href="#">
                              10
                            </a>
                          </li>

                          {/* Next Page Link */}
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <img src={PolygonB} alt="Move next" />
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
