
import axios from "axios";
import { BASE_URL } from "../store/baseUrl";

export const loginUserApi = async (params) => {
    try {
        const response = await axios.post(`${BASE_URL}user/login`, params);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw error.response;
        } else {
            throw new Error(error.message);
        }
    }
};



export const registerApi =async(params)=>{
        try{
             const responce = await axios.post(`${BASE_URL}user/login`, params)
             return responce;
        }catch (error){
                console.log(error)
        }

}



export const createUserApi = async (params) => {
  
   
    try {
      const response = await axios.post(`${BASE_URL}User/create`, params);
      
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data; 
      } else {
        throw new Error(error.message);
      }
    }
  };

// --------------get all User --------------------//




export const gatAllUser = async (params) => {  
  console.log(params,"params");
  
  try {   
    // const response = await axios.get(`${BASE_URL}Club/getall?page_no=1&page_size=100`);
    const response = await axios.get(`${BASE_URL}User/getall?page_no=1&page_size=5`, {
      headers: {
        "x-access-token": params.token, // Pass the token in the correct header
      }
    });
    return response.data; 
  } catch (error) {    
    throw error;
  }
};





// ----------player api intrigration--------- //




export const createPlayerApi = async (params) => {
  
  try {
    const response = await axios.post(`${BASE_URL}Player/create`, params, {
      headers: {
        "x-access-token": params.token, // Pass the token in the correct header
      }
    });
    
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw new Error(error.message);
    }
  }
};



// ----------Agent api intrigration--------- //


export const createAgentApi = async (params) => {
  
  
  try {
    const response = await axios.post(`${BASE_URL}Agent/create`, params, {
      headers: {
        "x-access-token": params.token, // Pass the token in the correct header
      }
    });
    
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw new Error(error.message);
    }
  }
};




  // uptodate user api intregrated---------------//




export const updateUserApi = async (params) => {
  const { data, id } = params;  
  
  try {
    const response = await axios.put(`${BASE_URL}User/update/${id}`, data, {
      headers: {
        "x-access-token": params.token, 
      }
    });
    
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw new Error(error.message);
    }
  }
};

// ----------School api intrigration--------- //




export const createSchoolApi = async (params) => {
  
  
  try {
    const response = await axios.post(`${BASE_URL}SoccerSchool/create`, params, {
      headers: {
        "x-access-token": params.token, // Pass the token in the correct header
      }
    });
    
    return response.data;
  } catch (error) {
    // console.error('API Error:', error.response ? error.response.data : error.message); // Debugging
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw new Error(error.message);
    }
  }
};


  // ----------Club api intrigration--------- //




export const createClubApi = async (params) => {
  
  try {
    const response = await axios.post(`${BASE_URL}Club/create`, params, {
      headers: {
        "x-access-token": params.token, 
      }
    });
    
    return response.data;
  } catch (error) {
    // console.error('API Error:', error.response ? error.response.data : error.message); // Debugging
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw new Error(error.message);
    }
  }
};

// ----------creat reel api --------- //

export const createReelApi = async ({userId, token, formData}) => {
  try {
      const response = await axios.post(
          `${BASE_URL}Reel/uploadReel`, 
          formData, 
          {
              headers: {
                  'x-access-token': token, // Ensure 'x-access-token' matches what your API expects
                  'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: (progressEvent) => {
                  // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  // console.log(`Upload Progress: ${percentCompleted}%`);
              }
          }
      );
      return response.data;
  } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
          throw error.response.data;
      } else {
        throw new Error(error.message);
      }
  }
};











// ----------GET REEL --------- //



export const getReelApi = async (params) => {
try {
  const response = await axios.get(`${BASE_URL}Reel/getall`);
  return response.data;
} catch (error) {
  if (axios.isAxiosError(error) && error.response) {
    throw new Error(error.response.data.message || 'An error occurred');
  } else {
    throw new Error(error.message);
  }
}
};




// ---------- REEL like api --------- //


export const likeReelApi = async (userId, reelId, action) => {
  try {
    const response = await axios.post(`${BASE_URL}Reel/${reelId}/liked`, { user :userId });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'An error occurred');
    } else {
      throw new Error(error.message);
    }
  }
};

// ---------- REEL like remove  api --------- //

export const likeRemoveApi = async (userId, reelId, action) => {

  try {
    const response = await fetch(`${BASE_URL}Reel/${reelId}/liked`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: userId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'An error occurred');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};


// ---------- REEL Dislike api --------- //


export const DislikeReelApi = async (userId, reelId, action) => {
  try {
    const response = await axios.post(`${BASE_URL}Reel/${reelId}/dislike`, { user :userId });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'An error occurred');
    } else {
      throw new Error(error.message);
    }
  }
};

// ---------- REEL Dislike remove api --------- //



export const DislikeRemovelApi = async (userId, reelId, action) => {

  try {
    const response = await fetch(`${BASE_URL}Reel/${reelId}/dislike`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: userId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'An error occurred');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
};




// ----------change password api intrigration--------- //


export const changePasswordApi = async (params) => {
  try {
    const url = `${BASE_URL}User/changePassword/${params.id}`;
    
    const response = await axios.put(url, {
      oldPassword: params.oldPassword,
      newPassword: params.newPassword
    }, {
      headers: {
        'Authorization': `Bearer ${params.token}` 
      }
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
     
      throw new Error(error.message);
    }
  }
};





// get a player  Api

export const gatPlayerApi = async (data) => {
const {userId,endPoint,token}=data
  try {
 
    const response = await axios.get(`${BASE_URL}${endPoint.value}/getByUserId/${userId}`,{
      headers: {
        "x-access-token": token, 
      }
    });
    return response.data; 
  } catch (error) {
    // console.error('API Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};



// player upload image api 


export const imageUploadApi = async (data) => {
const { id, token, formData, endPoint }= data
  try {
    const response = await axios.post(`${BASE_URL}${endPoint.value}/${id}/profilePicture`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    // console.error('API Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};


// get all Player Api

export const gatAllPlayerApi = async (data) => {  
    try {   
      const response = await axios.get(`${BASE_URL}Player/getall?page_no=1&page_size=100'`);
      return response.data; 
    } catch (error) {
      // console.error('API Error:', error.response ? error.response.data : error.message);
      throw error;
    }
  };


  // follow a player api 


  export const followPlayerApi = async (params) => {
    const {playerId,token, targetId }= params  
    try {
      const response = await axios.post(`${BASE_URL}Player/${playerId}/follow/${targetId}`, params, {
        headers: {
          "x-access-token": token, 
        }
      });
      
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response ? error.response.data : error.message);
      if (axios.isAxiosError(error) && error.response) {
        throw error.response.data;
      } else {
        throw new Error(error.message);
      }
    }
  };



    // Unfollow a player api 


    export const unfollowPlayerApi = async (params) => {
      const {playerId,token, targetId }= params  
      
      try {
        const response = await axios.delete(`${BASE_URL}Player/${playerId}/unfollow/${targetId}`, {
          headers: {
            "x-access-token": token, 
          }
        });
        
        return response.data;
      } catch (error) {
        console.error('API Error:', error.response ? error.response.data : error.message); // Debugging
        if (axios.isAxiosError(error) && error.response) {
          throw error.response.data;
        } else {
          throw new Error(error.message);
        }
      }
    };

    // --------------------my following api------------------


    export const myFollowingApi = async (data) => {
      const {playerId,token}= data
      
        try {
          const response = await axios.get(`${BASE_URL}Player/${playerId}/following`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });
          return response.data;
        } catch (error) {
          console.error('API Error:');
        }
      };



// search player api  


export const SearchPlayerApi = async ({ name, token }) => {
  try {
    const response = await axios.get(
      `https://mosis-backend.cradle.services/Player/search?Name=${name}`, // Use dynamic search term
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return response; // Return the full response
  } catch (error) {
    console.error('API Error:', error);
    throw error; // Rethrow error to handle it in the component
  }
};


// get all Club Api

export const gatAllClubApi = async (data) => {  
  try {   
    const response = await axios.get(`${BASE_URL}Club/getall?page_no=1&page_size=100`);
    return response.data; 
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};


// search Club api  


export const SearchClubApi = async ({ name, token }) => {
  try {
    const response = await axios.get(
      `https://mosis-backend.cradle.services/Club/search?Name=${name}`, 
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error; 
  }
};


// get all School Api

export const gatAllSchoolApi = async (data) => {  
  try {   
    const response = await axios.get(`${BASE_URL}SoccerSchool/getall?page_no=1&page_size=100`);
    return response.data; 
  } catch (error) {
    console.error('API Error:', error.response ? error.response.data : error.message);
    throw error;
  }
};


// search school api  


export const SearchSchoolApi = async ({ name, token }) => {
  try {
    const response = await axios.get(
      `https://mosis-backend.cradle.services/SoccerSchool/search?Name=${name}`, 
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error('API Error:', error);
    throw error; 
  }
};

