import React, { useState } from 'react';
import Player from './Player';
import './AddnewPlayer.css';
import searchLogo from "../../assets/search.svg";
import filter from "../../assets/mage_filter.png";
import AddplayerModal from '../shared/Modal/AddplayerModal';

const AddnewPlayer = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className='mb-5 d-flex justify-content-between align-items-center'>
        <div className="search-sectionn position-relative">
          <img src={searchLogo} alt="Search" />
          <input
            type="text"
            placeholder="Search"
          />
          <img className='Filter_img' src={filter} alt="Search" />
        </div>
        <div className='width_40per text-right'>
          <button className='add_player_btn' onClick={handleOpenModal}>
            <i className="fa-solid fa-plus"></i>
             Add Player
          </button>
        </div>
      </div>

      <div>
        <Player />
      </div>

      <AddplayerModal showModal={showModal} closeModal={handleCloseModal} />
    </>
  );
}

export default AddnewPlayer;
