import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPlayerApi } from "../api/user";

const initialState = {
  createUser: null,
  createUserLoading: false,
  createUserError: null,

  player: {      
  currentClubId: "",
  agentId: "",
  currentSchoolId: "",
  Name: "",
  // displayName: "",
  whatsapp: "",
  cpf: "",
  number: "",
  // playingTeam: "",
  currentHeight: "",
  currentWeight: "",
  birthDate: "",
  position: "",
  playingSince: "",
  gender: "",
  about: "",
  location: "",
  experience: [
    {
      // league: "",
      // location: "",
      achievements: "",
      startDate: "",
      endDate: "",
      position: "",
      tshirtNumber: "",
      playingTeam: ""
    }
  ]
  }



};
// createPlayerApi-------//


export const creatPlayerSlice = createAsyncThunk(
  'player/createPlayer',
  async (params, { rejectWithValue }) => { 
    try {
      const response = await createPlayerApi(params);
      return response;
    } catch (error) {
   
      return rejectWithValue(error.data || { message: 'An unknown error occurred' });
    }
  }
);


const FormDataSlice = createSlice({
  name: 'FormDataSlice',
  initialState,
  reducers: {
    changeData: (state, action) => {
      const { name, value } = action.payload;
      //console.log({ name, value }, 'new');
      state.player[name] = value;
    }


  },
  extraReducers: (builder) => {
    builder
      .addCase(creatPlayerSlice.pending, (state) => {
        state.createUserLoading = true;
        state.createUserError = null;
      })
      .addCase(creatPlayerSlice.fulfilled, (state, action) => {
        //console.log("Home action ", action)
        state.createUser = action.payload.data;
        state.createUserLoading = false;
        state.createUserError = null;
      })
      .addCase(creatPlayerSlice.rejected, (state, action) => {
        state.createUserError = action.payload;
        state.createUserLoading = false;
      })

  }
});

export const { changeData } = FormDataSlice.actions
export default FormDataSlice.reducer;
