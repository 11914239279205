import React, { useState, useEffect, useRef } from "react";
import headerdp from "../../assets/header-dp.svg";
import reelIcon from "../../assets/reels_icon.svg";
import CreatePlaylistModal from "../shared/Modal/CreatePlaylistModal";
import CreateReelModal from "../shared/Modal/CreatreelModal";
import {
  DislikeReelApi,
  DislikeRemovelApi,
  getReelApi,
  likeReelApi,
  likeRemoveApi,
} from "../../redux/api/user";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation } from "react-router-dom";
import Video from "./VideoPlayer/Video";
import "./VideoPlayer/App.css";

const Reels = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reelId = queryParams.get("reelid");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSaveDropdownOpen, setIsSaveDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [reels, setReels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [isLiking, setIsLiking] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleSaveDropdown = () => setIsSaveDropdownOpen(!isSaveDropdownOpen);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const openPlaylistModal = () => setShowPlaylistModal(true);
  const closePlaylistModal = () => setShowPlaylistModal(false);

  const containerRef = useRef(null);
  const videoRefs = useRef([]);

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const loginData = localStorage.getItem("loginData");
    if (loginData && loginData !== "undefined") {
      try {
        const role = JSON.parse(loginData);
        setUserId(role?._id);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.warn("loginData is not available or is undefined");
    }
  }, []);

  useEffect(() => {
    const fetchReels = async () => {
      try {
        setLoading(true);
        const data = await getReelApi();
        if (reelId) {
          const reelData = data?.data.find((reel) => reel._id === reelId);
          if (reelData) {
            setReels([reelData]);
            setCurrentIndex(0); // Set index to 0 to display the reel
          } else {
            setError("Reel not found.");
            setReels([]);
          }
        } else {
          setReels(data?.data || []);
        }
      } catch (err) {
        setError(err.message || "Failed to fetch reels.");
      } finally {
        setLoading(false);
      }
    };

    fetchReels();
  }, [reelId]);

  useEffect(() => {
    setIsDisliked(isDisLikedByUser());
  }, [reels, currentIndex, userId]);
  

  useEffect(() => {
    if (videoRefs.current[currentIndex]) {
      videoRefs.current[currentIndex].play();
    }
  }, [currentIndex]);

  const isLikedByUser = () => {
    const reel = reels[currentIndex];
    return reel?.likes?.includes(userId);
  };

  const isDisLikedByUser = () => {
    const reel = reels[currentIndex];
    return reel?.dislikes?.includes(userId);
  };

  const handleLike = async () => {
    try {
      if (!userId) return; 

      const res = isLikedByUser();
      if (res) {
        const reelData = await likeRemoveApi(userId, reels[currentIndex]._id);
        reels[currentIndex] = reelData;
        setIsLiked(false);
      } else {
        const reelData = await likeReelApi(userId, reels[currentIndex]._id);
        reels[currentIndex] = reelData;
        setIsLiked(true);
        setIsDisliked(false);
      }
    } catch (err) {
      console.error("Error handling reel like:", err.message);
    }
  };

  const handleDisLike = async () => {
    try {
      if (!userId) return; 

      const res = isDisLikedByUser();
      if (res) {
        const reelData = await DislikeRemovelApi(userId, reels[currentIndex]._id);
        reels[currentIndex] = reelData;
        setIsDisliked(false);
      } else {
        const reelData = await DislikeReelApi(userId, reels[currentIndex]._id);
        reels[currentIndex] = reelData;
        setIsDisliked(true);
        setIsLiked(false);
      }
    } catch (err) {
      console.error("Error handling reel dislike:", err.message);
    }
  };

  const handleVideoEnd = () => {
    const nextIndex = Math.min(currentIndex + 1, reels.length - 1);
    if (nextIndex !== currentIndex) {
      setCurrentIndex(nextIndex);

      if (containerRef.current) {
        const nextVideo = videoRefs.current[nextIndex];
        if (nextVideo) {
          containerRef.current.scrollTo({
            top: nextVideo.offsetTop,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, clientHeight } = containerRef.current;
      const index = Math.round(scrollTop / clientHeight);
      if (index !== currentIndex) {
        setCurrentIndex(index);
      }
    }
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-white" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (reels.length === 0) {
    return  <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-white" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
  }

  return (
    <div>
      <div className="reels_top_header d-flex" style={{ color: "#fff" }}>
        <h6>Reels</h6>
        <button className="reel-btn" onClick={openModal}>
          <img src={reelIcon} alt="Reel Icon" />
          <span>Add Reels</span>
        </button>
      </div>

      <div className="Reels_main_wrap">
        <div className="container d-flex">
          <div className="reel_video_wrap">
            <div className="shorts_vedios"
                 ref={containerRef}
                 onScroll={handleScroll}
                 style={{ overflowY: "auto" }}>
              {reels.map((item, index) => (
                <Video
                  key={`video${item._id}`}
                  video={item}
                  isActive={index === currentIndex}
                  ref={(el) => (videoRefs.current[index] = el)}
                  onEnded={handleVideoEnd}
                />
              ))}
            </div>
          </div>

          <div className="col-md-6">
            <div>
              <div className="profile_info_features_wrap">
                <div className="profile_info_top_box d-flex gap-10px">
                  <div className="top_box_left_dp">
                    <Link to="/reel">
                      <img src={headerdp} alt="Profile" />
                    </Link>
                  </div>
                  <div className="top_box_right d-flex justify-content-between">
                    <div>
                      <h6>{reels[currentIndex]?.userId?.Name || "user"}</h6>
                      <h2>{reels[currentIndex]?.title}</h2>
                      <p>
                        Today<span className="dot"></span>30K Views
                      </p>
                    </div>
                    <div>
                      <div className="Profile_Dropdown_wrap" onClick={toggleDropdown}>
                        <span className="on_click_open_btn">
                          <i className="fa-solid fa-ellipsis-vertical"></i>
                        </span>
                        {isDropdownOpen && (
                          <div className="dropdown-menuu show">
                            <Link className="dropdown-item" to="">
                              <i className="fa-solid fa-bars"></i>
                              <span className="ml-3">Description</span>
                            </Link>
                            <Link className="dropdown-item" to="">
                              <i className="fa-solid fa-closed-captioning"></i>
                              <span className="ml-3">Caption</span>
                            </Link>
                            <Link className="dropdown-item" to="">
                              <i className="fa-solid fa-triangle-exclamation"></i>
                              <span className="ml-3">Report</span>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between like_share_save_etc_options">
                  <div onClick={handleLike}>
                    <i
                      className={`fa-solid fa-thumbs-up ${isLiked ? "text-success" : ""}`}
                      style={{ pointerEvents: isLiking ? "none" : "auto" }}
                    ></i>
                    <p>{reels[currentIndex]?.likes?.length || 0} Like</p>
                  </div>
                  <div onClick={handleDisLike}>
                    <i
                      className={`fa-solid fa-thumbs-down ${isDisliked ? "text-success" : ""}`}
                      style={{ pointerEvents: isDisliked ? "none" : "auto" }}
                    ></i>
                    <p>{reels[currentIndex]?.dislikes?.length || 0} Dislike</p>
                  </div>
                  <div>
                    <i className="fa-solid fa-share"></i>
                    <p>Share</p>
                  </div>
                  <div onClick={toggleSaveDropdown}>
                    <i className="fa-solid fa-bookmark"></i>
                    <p>Save</p>
                    {isSaveDropdownOpen && (
                      <div className="dropdown-menuu dropdown_for_save show">
                        <p className="dropdown-header">Save to..</p>
                        <div className="padding-l-r-18">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="watchLater" />
                            <label className="form-check-label" htmlFor="watchLater">
                              Watch Later
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="tutorial" />
                            <label className="form-check-label" htmlFor="tutorial">
                              Tutorial
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="workout" />
                            <label className="form-check-label" htmlFor="workout">
                              Workout
                            </label>
                          </div>
                        </div>
                        <div className="dropdown-footer" onClick={openPlaylistModal}>
                          <i className="fa-solid fa-plus"></i>
                          <span>Create new playlist</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateReelModal showModal={showModal} closeModal={closeModal} />
      <CreatePlaylistModal showModal={showPlaylistModal} closeModal={closePlaylistModal} />
    </div>
  );
};

export default Reels;
