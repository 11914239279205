import React from 'react'
import { NewPlayersData } from "../../shared/mock.data";

const Offers = () => {

  

  return (
    <div>
      <div className="offers_main_wrap">
        <div className="offers_new_players">
          <div className="container">
            <h6 className='main_head'>Checkout these new players</h6>
            <div className="row">
            {NewPlayersData.map((item) => (

              <div key={item.id} className="col-xl-3">
                <div class="card">
                  <div class="card-body">
                    <div className="card_img_wrap">
                      <img src={item.src} alt="" />
                    </div>
                    <div className="match_info_subtitles">
                      <div className="player_dp_name_wrap d-flex align-items-center">
                        <div className="profile_dp_wrap">
                          <img src={item.dp} alt="" />
                        </div>
                        <h5>{item.title}</h5>
                      </div>
                      <div className="offer_name_here">
                        <h6>{item.description}</h6>
                        <a>{item.price}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            </div>
          </div>
        
        </div>
      </div>
    </div>
  )
}

export default Offers
