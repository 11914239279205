import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPlayerSlice } from '../../redux/slice/GetallPlayer';
import { toast } from 'react-toastify';


const Player = () => {
  const role = JSON.parse(localStorage.getItem('loginData'));
  const rolename = role?.role;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const player = useSelector((state) => state.players);
  const AllPlayer = Array.isArray(player) ? player : player.players || [];
  

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const res = await dispatch(getAllPlayerSlice());
      } catch (error) {
        console.error('Error fetching players:', error);
        toast.error('Failed to fetch players');
      }
    };

    fetchPlayers();
  }, [dispatch]);



  return (
    <div className="players_list_main_wrap">
      <div className="container">
        <div className="row flex_wrap_wrap">
          {AllPlayer && AllPlayer.map((player) => (
            <div key={player._id} className="width-202px col-md-12 mb-4">
              <div className="card custom-card">
                <div className="card-body">
                  <img src={`https://mosis-backend.cradle.services${player.face_picture}`} alt={player.Name} />
                  <h6>{player.Name}</h6>
                  <button
                    onClick={() =>
                      navigate(`/${rolename}/playerprofile`, {
                        state: { player },
                      })
                    }
                  >
                    Check
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Player;
