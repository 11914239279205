import React from 'react';
import { Modal } from 'react-bootstrap';
import ModalX from "../../../assets/modal-x.svg";
import './Modal.css';

const CreatePlaylistModal = ({ showModal, closeModal }) => {
    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header>
                <div className="tab_on_topp">
                    <h6>Create New Playlist</h6>
                </div>
                <span className='modal_close_cross'  onClick={closeModal}>
                    <img className='cursor' src={ModalX} alt="cross-icon" />
                </span>
            </Modal.Header>
            <Modal.Body>
          <div className="modal_body_form_wrap">
            <form>
              <div className="form_wrap">
                <label htmlFor="playlistName">Title</label>
                <input type="text" id="playlistName" />
              </div>
              <div className="form_wrap description_height">
                <label htmlFor="description">Description</label>
                <input type="text" />
              </div>
           
              <div>
                <h6 className="visibility_h6">Visibility</h6>
              <div style={{ display: "flex", alignItems: "center",marginBottom: "46px" }}>
                <div
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="firstCheckbox"
                    style={{ width: "15px", height: "15px", fontSize: "20px" }}
                  />
                  <label
                    htmlFor="firstCheckbox"
                    style={{
                      marginLeft: "10px",
                    marginBottom: "0",

                      color: "white",
                      fontSize: "20px",
                    }}
                  >
                    Public
                  </label>
                </div>
                <div
                  style={{
                    marginLeft: "150px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "20px",
                  }}
                >
                  <input
                    type="checkbox"
                    id="secondCheckbox"
                    style={{ width: "15px", height: "15px", fontSize: "20px" }}
                  />
                  <label
                    htmlFor="secondCheckbox"
                    style={{ marginLeft: "10px", 
                    marginBottom: "0",
                    color: "white" }}
                  >
                    Private
                  </label>
                </div>
                <div
                  style={{
                    marginLeft: "150px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="secondCheckbox"
                    style={{ width: "15px", height: "15px", fontSize: "20px" }}
                  />
                  <label
                    htmlFor="secondCheckbox"
                    style={{
                    marginBottom: "0",
                    marginLeft: "10px",
                      color: "white",
                      fontSize: "20px",
                    }}
                  >
                    Unlisted
                  </label>
                </div>
              </div>
              </div>
              <div className="post_video_btn text-center">
                <button type="button" >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
        </Modal>
    );
};

export default CreatePlaylistModal;
