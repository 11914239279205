import React, { useState } from 'react';
import BannerA from "../../assets/banner_a.svg";
import Modal from '../shared/Modal/Modal';
import PlayerCreatReelModal from '../shared/Modal/PlayerCreatReelModal';

const CreateVideo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
 

  const loginData = localStorage.getItem("loginData");

  let role = {}; 
  if (loginData && loginData !== 'undefined') {
    try {
      role = JSON.parse(loginData);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  } else {
    console.warn('loginData is not available or is undefined');
  }

  const userRole = role?.role


  return (
    <div>
      <div className="create_video_banner_wrap">
        <img src={BannerA} alt="" />
        <div className="create_video_btn">
          <button onClick={openModal}>Create reel</button>
        </div>
      </div>
      
      {userRole === 'player' ? (
         <PlayerCreatReelModal showModal={isModalOpen} closeModal={closeModal} />
      ) : (
        <Modal isShow={isModalOpen} onClose={closeModal} />
      )}
    </div>
  );
};

export default CreateVideo;




