import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../pages/sidebar/Sidebar";
import Header from "../../components/header/header";
import ModalX from "../../assets/modal-x.svg";
import { Outlet } from "react-router-dom";
import "../../pages/sidebar/Sidebar";
import ProtectedRoutes from "../../protectedRoutes";

const Layout = () => {
  const location = useLocation(); 

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleResize = () => {
    if (window.innerWidth <= 991) {
      setIsSidebarVisible(false);
    } else {
      setIsSidebarVisible(true);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const loginData = localStorage.getItem("loginData");

  let role = {};
  if (loginData && loginData !== 'undefined') {
    try {
      role = JSON.parse(loginData);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  } else {
    console.warn('loginData is not available or is undefined');
  }
  
  
  
  
    
    const rolename = role?.role;



  const hiddenSidebarRoutes = [`/${rolename}/Changesubs`, `/${rolename}/Billing`];
  const shouldHideSidebar = hiddenSidebarRoutes.includes(location.pathname);

  const shouldRemoveClassFromOutlet = hiddenSidebarRoutes.includes(location.pathname);

  return (
    <div>
      <ProtectedRoutes>  
      <Header />
      <div className="header-fixed">
      </div>

      <div className="container-fluid pt-100px">
        <div className="row">
          {!shouldHideSidebar && isSidebarVisible && (
            <div className="width_sidebar">
              <div className="sidebar-fixed">
                <div className="text-end">
                  <div onClick={() => setIsSidebarVisible(false)}>
                    <img src={ModalX} alt="Close Sidebar" style={{cursor: 'pointer'}} />
                  </div>
                </div>
                <Sidebar />
              </div>
            </div>
          )}
          <div className={shouldHideSidebar ? "full_width" : isSidebarVisible ? "width_outlet" : "full_width"}>
            {!shouldHideSidebar && !isSidebarVisible && (
              <div className="text-left open_sidebar pt-2" onClick={() => setIsSidebarVisible(true)} style={{cursor: 'pointer'}}>
                <i class="fa fa-list" aria-hidden="true"></i>

              </div>
            )}
            <div className="row">
              <div className={shouldRemoveClassFromOutlet ? "" : "content_main_wrap"}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      </ProtectedRoutes>
    </div>
  );
};

export default Layout;
