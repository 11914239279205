// import React, { useRef, useState } from "react";
// import "./Negotiation.css";
// import userImage from "../../assets/Ellipse 172.png";
// import EmojiIcon from "../../assets/emoji_icon.svg";
// import AddIcon from "../../assets/add_icon.svg";
// import EmojiPicker from "emoji-picker-react";
// import NegotiationModal from "./NegotiationModal";

// const Negotiation = () => {
//   const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
//   const [isThreeDotDropdownVisible, setIsThreeDotDropdownVisible] =useState(false);
//   const [isPlusDropdownVisible, setIsPlusDropdownVisible] = useState(false);
//   const [isMediaLinksDocsModalOpen, setIsMediaLinksDocsModalOpen] =useState(false);
//   const fileInputRef = useRef(null);

//   const toggleEmojiPicker = () => {
//     setIsEmojiPickerVisible(!isEmojiPickerVisible);
//   };

//   const toggleThreeDotDropdown = () => {
//     setIsThreeDotDropdownVisible(!isThreeDotDropdownVisible);
//   };

//   const togglePlusDropdown = () => {
//     setIsPlusDropdownVisible(!isPlusDropdownVisible);
//   };

//   const openMediaLinksDocsModal = () => {
//     setIsMediaLinksDocsModalOpen(true);
//   };
//   const closeMediaLinksDocsModal = () => {
//     setIsMediaLinksDocsModalOpen(false);
//   };

//   const [message,setMessage]=useState('');
//   const [fileType, setFileType] = useState('');

//   const handleChange = (value)=>{
//     //console.log(message ,'emojiObject')
//     setMessage((prevInputValue) => prevInputValue + value);
//   }
//   const onEmojiClick = (emojiObject) => {
//     handleChange(emojiObject?.emoji)
//   };

//   const openFilePicker = (type) => {
//     setFileType(type); // Set file type based on dropdown choice
//     if (fileInputRef.current) {
//       fileInputRef.current.setAttribute('accept', type);
//       fileInputRef.current.click();
//     }
//   };

//   const handleFileChange = (event) => {
//     const files = event.target.files;
//     if (files.length > 0) {
//       //console.log('Selected file:', files[0]);
//       // Handle file processing here
//     }
//   };

// const addUrlToMessage = (url) => {
//   setMessage((prevMessage) => prevMessage + url);
//   closeMediaLinksDocsModal();
// };

//   return (
//     <div>
//       <div className="negotiation_main_wrap">
//         <div className="negotiation_top_heading">
//           <h1>Negotiation</h1>
//         </div>
//         <div className="negotiation_box_wrap">
//           <div className="row">
//             <div className="col-lg-6 border_right_green ">
//               <div className="negotiation_box_left mt-4">
// <div className="negotiation_box_row active_row d-flex justify-content-between">
//   <div className="user_dp_name_here d-flex gap-3 align-items-center">
//     <img src={userImage} alt="" />
//     <div>
//       <h5>Name of Agent</h5>
//       <p>dd-mm-yyyy</p>
//     </div>
//   </div>
//   <button className="under_negotiation_btn">
//     Under Negotiation
//   </button>
// </div>
//                 <div className="negotiation_box_row d-flex justify-content-between">
//                   <div className="user_dp_name_here d-flex gap-3 align-items-center">
//                     <img src={userImage} alt="" />
//                     <div>
//                       <h5>Name of Agent</h5>
//                       <p>dd-mm-yyyy</p>
//                     </div>
//                   </div>
//                   <button className="requested_btn">Requested</button>
//                 </div>
//                 <div className="negotiation_box_row d-flex justify-content-between">
//                   <div className="user_dp_name_here d-flex gap-3 align-items-center">
//                     <img src={userImage} alt="" />
//                     <div>
//                       <h5>Name of Agent</h5>
//                       <p>dd-mm-yyyy</p>
//                     </div>
//                   </div>
//                   <button className="requested_btn">Requested</button>
//                 </div>
//                 <div className="negotiation_box_row d-flex justify-content-between">
//                   <div className="user_dp_name_here d-flex gap-3 align-items-center">
//                     <img src={userImage} alt="" />
//                     <div>
//                       <h5>Name of Agent</h5>
//                       <p>dd-mm-yyyy</p>
//                     </div>
//                   </div>
//                   <button className="requested_btn">Requested</button>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-6 ">
//               <div className="negotiation_box_right position-relative">
//                 <div className="message-icon cursor" onClick={toggleThreeDotDropdown}>
//                   <i className="fa-solid fa-ellipsis"></i>
//                 </div>
//                 {isThreeDotDropdownVisible && (
//                   <div className="three-dot-dropdown">
//                     <div className="dropdown-item">
//                       <div className="dropdown-search">
//                         <input type="text" placeholder="search" />
//                         <i className="fa-solid fa-search mr-2"></i>
//                       </div>
//                     </div>
//                     <div className="dropdown-item justify-content-between">
//                       <span>Negotiation Status</span>
//                       <i className="fa-solid fa-chevron-down"></i>
//                     </div>
//                     <div
//                       className="dropdown-item"

//                     >
//                       <input type="checkbox" />
//                       <span>Media, Links, Docs</span>
//                     </div>
//                     <div className="dropdown-item">
//                       <input type="checkbox" />
//                       <span>Mute Notifications</span>
//                     </div>
//                     <div className="dropdown-item">
//                       <input type="checkbox" />
//                       <span>Report</span>
//                     </div>
//                     <div className="dropdown-item">
//                       <input type="checkbox" />
//                       <span>Block</span>
//                     </div>
//                   </div>
//                 )}
//                 <div className="massage-main mt-4">
//                   <div className="massage-text1 col-6">
//                     <div className="massage-set">
//                       <p>
//                         Lorem ipsum dolor sit amet consectetur. Pretium eget
//                         eleifend nisi convallis id nunc elit. Nascetur lacus
//                         pellentesque donec sed eget a consequat. Nisl id odio
//                         enim tempor nisl amet. Maecenas viverra nisi lorem
//                         libero eu.
//                       </p>
//                       <span>12:35 pm</span>
//                     </div>
//                   </div>
//                   <div className="col-6 massage-text2">
//                     <div className="massage-set">
//                       <p>
//                         Nascetur lacus pellentesque donec sed eget a consequat.
//                         Nisl id odio enim tempor nisl amet. Maecenas viverra
//                         nisi lorem libero eu..
//                       </p>
//                       <span>12:35 pm</span>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="message_right_footer d-flex justify-content-between align-items-center">
//                   <div className="emoji_input_wrap d-flex gap-3">
//                     <div className="footer-icon" onClick={toggleEmojiPicker}>
//                       <img className="cursor" src={EmojiIcon} alt="" />
//                     </div>
//                     {isEmojiPickerVisible && (
//                       <div className="emoji-picker-wrapper">
//                         <EmojiPicker theme="dark" onEmojiClick={onEmojiClick} />
//                       </div>
//                     )}
//                     <input type="text" placeholder="Write your response..."  value={message} onChange={(e)=>setMessage(e.target.value)} />
//                   </div>

//                   <div className="right_Add_icon">
//                     <div className="footer-icon" onClick={togglePlusDropdown}>
//                       <img className="cursor" src={AddIcon} alt="" />
//                     </div>
//                     {isPlusDropdownVisible && (
//                       <div className="plus-dropdown">
//                         <div className="dropdown-item" onClick={() => openFilePicker('image/*')} >
//                           <i className="fa-solid fa-image"></i>
//                           <span>Image</span>
//                         </div>
//                         <div className="dropdown-item" onClick={() => openFilePicker('video/*')} >
//                           <i className="fa-solid fa-video"></i>
//                           <span>Video</span>
//                         </div>
//                         <div className="dropdown-item" onClick={() => openFilePicker('application/pdf')} >
//                           <i className="fa-solid fa-file"></i>
//                           <span>Document</span>
//                         </div>
//                         <div className="dropdown-item"  onClick={openMediaLinksDocsModal}>
//                           <i className="fa-solid fa-link"></i>
//                           <span>Link</span>
//                         </div>
//                       </div>
//                     )}
//                     <NegotiationModal
//                       isOpen={isMediaLinksDocsModalOpen}
//                       onClose={closeMediaLinksDocsModal}
//                       onAddUrl={addUrlToMessage}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//        {/* Hidden file input */}
//        <input
//         type="file"
//         ref={fileInputRef}
//         style={{ display: 'none' }}
//         onChange={handleFileChange}
//       />
//     </div>
//   );
// };

// export default Negotiation;

import React, { useEffect, useState, useRef } from "react";
import "./Negotiation.css"; // Assuming you have a custom CSS file for styling
import EmojiPicker from "emoji-picker-react";
import userImage from "../../assets/Ellipse 172.png";
import EmojiIcon from "../../assets/emoji_icon.svg";
import AddIcon from "../../assets/add_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  chatMsg,
  fetch_friend_Chats,
  initializeSocket,
  setRoom,
} from "../../redux/slice/ChatSocket";
import { myFollowingApis } from "../../redux/slice/user";

const Negotiation = () => {
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
  const [isPlusDropdownVisible, setIsPlusDropdownVisible] = useState(false);
  const [isThreeDotDropdownVisible, setIsThreeDotDropdownVisible] =
    useState(false);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]); // For storing chat messages
  const [fileType, setFileType] = useState("");
  const socketRef = useRef(null);
  const fileInputRef = useRef(null);
  const messageContainerRef = useRef(null);

  // Handle file uploads
  const openFilePicker = (type) => {
    setFileType(type);
    if (fileInputRef.current) {
      fileInputRef.current.setAttribute("accept", type);
      fileInputRef.current.click();
    }
  };

  const toggleThreeDotDropdown = () => {
    setIsThreeDotDropdownVisible(!isThreeDotDropdownVisible);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      console.log("Selected file:", files[0]); // Handle file upload logic
    }
  };

  // Handle Emoji Click
  const toggleEmojiPicker = () => {
    setIsEmojiPickerVisible(!isEmojiPickerVisible);
  };

  const onEmojiClick = (emojiObject) => {
    setMessage((prevInputValue) => prevInputValue + emojiObject.emoji);
  };

  // Scroll to the bottom when new messages are added
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const { _id, roleID, id, token } = JSON.parse(
    localStorage.getItem("loginData")
  );
  const store = useSelector((i) => i);
  const { Chat, roomADD } = store.socket;
  console.log(Chat, "ChatChatChatChat");

  const { users } = store.user;

  const [selected, SetSelected] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (roomADD) {
      dispatch(fetch_friend_Chats({ RoomID: roomADD }));
    }
  }, [roomADD]);

  useEffect(() => {
    if (_id) {
      dispatch(initializeSocket(_id));
    }
  }, []);
  useEffect(() => {
    if (roleID) {
      dispatch(myFollowingApis({ playerId: roleID, token: token }));
    }
  }, [dispatch, roleID]);

  const setRoomID = (id) => {
    const setRoomData = {
      name1: `${roleID}-${id}`,
      name2: `${id}-${roleID}`,
      members: [roleID, id],
    };

    dispatch(setRoom(setRoomData));
  };

  const sendMsg = (id) => {
    dispatch(
      chatMsg({
        msgTo: id,
        msg: message,
      })
    );
  };

  return (
    <div>
      <div className="negotiation_main_wrap">
        <div className="negotiation_top_heading">
          <h1>Negotiation</h1>
        </div>
        <div className="negotiation_box_wrap">
          <div className="row">
            <div className="col-lg-6 border_right_green">
              <div className="negotiation_box_left mt-4">
                {/* Replace this section with dynamic content for chat rooms */}
                {users &&
                  users.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        SetSelected(item);
                        setRoomID(item?._id);
                      }}
                      className={`negotiation_box_row d-flex justify-content-between ${
                        selected?._id === item?._id ? "active_row" : ""
                      }`}
                    >
                      <div className="user_dp_name_here d-flex gap-3 align-items-center">
                        <img src={`https://mosis-backend.cradle.services${item?.face_picture}`} alt="" />
                        <div>
                          <h5>{item?.Name}</h5>
                          <p>{item?.created_at}</p>
                        </div>
                      </div>
                      <button className="under_negotiation_btn">
                        Under Negotiation
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="negotiation_box_right position-relative">
                <div
                  className="message-icon cursor"
                  onClick={toggleThreeDotDropdown}
                >
                  <i className="fa-solid fa-ellipsis"></i>
                </div>
                {isThreeDotDropdownVisible && (
                  <div className="three-dot-dropdown">
                    <div className="dropdown-item">
                      <div className="dropdown-search">
                        <input type="text" placeholder="search" />
                        <i className="fa-solid fa-search mr-2"></i>
                      </div>
                    </div>
                    <div className="dropdown-item justify-content-between">
                      <span>Negotiation Status</span>
                      <i className="fa-solid fa-chevron-down"></i>
                    </div>
                    <div className="dropdown-item">
                      <input type="checkbox" />
                      <span>Media, Links, Docs</span>
                    </div>
                    <div className="dropdown-item">
                      <input type="checkbox" />
                      <span>Mute Notifications</span>
                    </div>
                    <div className="dropdown-item">
                      <input type="checkbox" />
                      <span>Report</span>
                    </div>
                    <div className="dropdown-item">
                      <input type="checkbox" />
                      <span>Block</span>
                    </div>
                  </div>
                )}
                {/* {selected ? <p className="text-light" >{selected?.Name}</p>:<></>} */}
                <div
                  className="massage-main mt-4"
                  style={{ height: "80vh" }}
                  ref={messageContainerRef}
                >
                  {Chat.map((msg, idx) => {
                    if (msg?.msgFrom?._id === id) {
                      return (
                        <div key={idx} className="massage-text2 col-6">
                          <div className="massage-set">
                            <p>{msg.msg}</p>
                            <span>{msg.createdAt}</span>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={idx} className="massage-text1 col-6">
                          <div className="massage-set">
                            <p>{msg.msg}</p>
                            <span>{msg.createdAt}</span>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="message_right_footer d-flex justify-content-between align-items-center">
                  <div className="emoji_input_wrap d-flex gap-3">
                    <div className="footer-icon" onClick={toggleEmojiPicker}>
                      <img className="cursor" src={EmojiIcon} alt="" />
                    </div>
                    {isEmojiPickerVisible && (
                      <div className="emoji-picker-wrapper">
                        <EmojiPicker theme="dark" onEmojiClick={onEmojiClick} />
                      </div>
                    )}
                    <input
                      type="text"
                      placeholder="Write your response..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          // Handle Enter key press here
                          sendMsg(selected?._id);

                          setMessage("");
                        }
                      }}
                    />
                  </div>

                  <div className="right_Add_icon">
                    <div
                      className="footer-icon"
                      onClick={() =>
                        setIsPlusDropdownVisible(!isPlusDropdownVisible)
                      }
                    >
                      <img className="cursor" src={AddIcon} alt="" />
                    </div>
                    {isPlusDropdownVisible && (
                      <div className="plus-dropdown">
                        <div
                          className="dropdown-item"
                          onClick={() => openFilePicker("image/*")}
                        >
                          <i className="fa-solid fa-image"></i>
                          <span>Image</span>
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => openFilePicker("video/*")}
                        >
                          <i className="fa-solid fa-video"></i>
                          <span>Video</span>
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => openFilePicker("application/pdf")}
                        >
                          <i className="fa-solid fa-file"></i>
                          <span>Document</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Negotiation;
