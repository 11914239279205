import React, { useEffect, useState, useRef } from "react";
import ArrowRight from "../../assets/arrow_right.svg";
import PaypalLogo from "../../assets/PayPal-Logo.svg";
import PicPay from "../../assets/picpay-logo.svg";
import ApplePay from "../../assets/apple-pay.svg";
import MasterCard from "../../assets/master-card.svg";
import ViSa from "../../assets/visa-logo.svg";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Swal from "sweetalert2";

const stripePromise = loadStripe("pk_test_51O1xkXB7s8EMuO0l7XBI6YDNb5JsjIIlKIgPIALaJJqw9dzukmfe9vj4I9A9XBrKK9EWJiUW5Y4VF7r8oXGNWBUY00dU6tXH78");

const Billing = () => {

  const loginData =  JSON.parse(localStorage.getItem('loginData'));

  const UserId =loginData?._id
  const UserName =loginData?.Name
  const UserEmail = loginData.email
  const UserNumber = loginData.number

  
  
  const [stripe, setStripe] = useState(null);
  const [clientSecret, setClientSecret] = useState(""); // Set this to the actual client secret
  const [elements, setElements] = useState(null);
  const [plan, setPlan] = useState("monthly");
  const [price, setPrice] = useState(49);
  const [subscriptionId, setSubscriptionId] = useState("66eebeee9b6744f325e197cc");
  const [gst, setGst] = useState(0);
  const [total, setTotal] = useState(0);
  const [subscriptionName, setSubscriptionName] = useState(" Basic");
  const paymentElementRef = useRef(null);


  // const initialValues = {
  //   name: "",
  //   cardNumber: "",
  //   expiry: "",
  //   cvc: "",
  // }; 
    useEffect(() => {
      if (clientSecret) {
        const initializeStripe = async () => {
          const stripeInstance = await stripePromise;
          if (stripeInstance) {
            setStripe(stripeInstance);
            const elementsInstance = stripeInstance.elements({ clientSecret });
            setElements(elementsInstance);
            const paymentElement = elementsInstance.create("payment");

            console.log(paymentElementRef.current);
            
            paymentElement.mount(paymentElementRef.current); 
          }
        };
    
        initializeStripe();
      }
    }, [clientSecret]);



    useEffect(() => {
      const gstAmount = (price * 18) / 100;
      setGst(gstAmount);
      setTotal(price + gstAmount);
    }, [price]);

    const handlePlanChange = (selectedPlan) => {
      if (selectedPlan === "monthly") {
        setPrice(49);
        setSubscriptionName(" Basic");
        setSubscriptionId("66eebeee9b6744f325e197cc");
      } else if (selectedPlan === "yearly") {
        setPrice(149);
        setSubscriptionId("66eebf869b6744f325e197ce");
        setSubscriptionName(" Premium");
      }
      setPlan(selectedPlan);
    };

 
    const handleSubmit = async (values) => {


      const url = 'https://mosis-backend.cradle.services/orders';
      const data = {
        userId: UserId,
        subscriptionId: subscriptionId,
        amount: total,
      };
      try {
        const response = await axios.post(url, data);
        // console.log(response.data);
        
        setClientSecret(response.data?.client_secret);
      } catch (error) {
        console.error('Error creating order:', error.response ? error.response.data : error.message);
      }
  
    };


   
    const handlePaymentConfirmation = async () => {
      if (stripe && elements && clientSecret) {
        try {
          const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  name: UserName,
                  email: UserEmail,
                  phone: UserNumber,
                  address: "address",
                },
              },
            },
            redirect: "if_required",
          });
  
          if (error) {
            Swal.fire({
              icon: "error",
              title: "Payment Failed",
              text: error.message,
            });
          } else if (paymentIntent?.status === "succeeded") {
            Swal.fire({
              icon: "success",
              title: "Payment Successful",
              text: "Your payment was successful!",
            });
          }
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Payment Error",
            text: "An error occurred while processing your payment.",
          });
        }
      }
    };

  return (
    <div className="subs_billing_wrap mb-5">
      {/* Header Section */}
      <div className="Change_Subscription_header">
        <h1>My Profile</h1>
        <div className="subscription_tabs text-white d-flex align-items-center gap-2">
          <p>Subscription</p>
          <img src={ArrowRight} alt="Arrow Right" />
          <p>Change Subscription</p>
          <img src={ArrowRight} alt="Arrow Right" />
          <span>Billing</span>
        </div>
      </div>
      <div className='mt-4'>
						<div id='payment-element' ref={paymentElementRef}></div>
					</div>
      {/* Billing Form Section */}
      <div className="billing_forms_wrap">
        <div className="container">
          <h6 className="main_head">Payment</h6>
          <div className="row">
            {/* Left Column - Billing Preferences and Order Summary */}
            <div className="col-lg-6">
              {/* Billing Preferences */}
              <div className="billing_box_wrap mb-4">
                <h5>Billing Preferences</h5>
                <div className="pay_monthly_annualy mb-3">
                  <div className="pay_monthly_annualy_row d-flex align-items-center justify-content-between mb-4">
                    <div className="radio-container">
                      <label className="custom-radio">
                      <input
                          type="radio"
                          name="radio-group"
                          checked={plan === "monthly"}
                          onChange={() => handlePlanChange("monthly")}
                        />
                        <span className="radio-checkmark"></span>
                        Pay Monthly
                      </label>
                    </div>
                    <h4>$49/Month</h4>
                  </div>
                  <div className="pay_monthly_annualy_row d-flex align-items-center justify-content-between mb-4">
                    <div className="radio-container">
                      <label className="custom-radio">
                      <input
                          type="radio"
                          name="radio-group"
                          checked={plan === "yearly"}
                          onChange={() => handlePlanChange("yearly")}
                        />
                        <span className="radio-checkmark"></span>
                        Pay Annually
                      </label>
                    </div>
                    <h4>$149/Year</h4>
                  </div>
                </div>
              </div>

              {/* Order Summary */}
              <div className="billing_box_wrap order_summary">
                <h6>Order summary</h6>
                <div className="order_summary_rows_wrap">
                  <div className="order_summary_row d-flex justify-content-between align-items-center mb-2">
                    <label className="custom-radio">Subscription</label>
                    <div>
                      <p>{subscriptionName}</p>
                      <span>${price}/{plan === "monthly" ? "Monthly" : "Yearly"}</span>
                    </div>
                  </div>
                  <div className="order_summary_row d-flex justify-content-between align-items-center mb-2">
                    <label className="custom-radio">Ranking Plan</label>
                    <div>
                      <p>Tier 1</p>
                      <span>${price}/{plan === "monthly" ? "Monthly" : "Yearly"}</span>
                    </div>
                  </div>
                  <div className="order_summary_row d-flex justify-content-between align-items-center mb-2">
                    <label className="custom-radio">Billing Frequency</label>
                    <div>
                      <p>{plan === "monthly" ? "Monthly" : "Yearly"}</p>
                    </div>
                  </div>
                  <div className="order_summary_row d-flex justify-content-between align-items-center mb-2">
                    <label className="custom-radio">Discount</label>
                    <div>
                      <p>0%</p>
                    </div>
                  </div>
                  <div className="order_summary_row d-flex justify-content-between align-items-center mb-2">
                    <label className="custom-radio">Tax</label>
                    <div>
                      <p>8%</p>
                      {/* <p>${gst.toFixed(2)}</p> */}
                    </div>
                  </div>
                  <div className="order_summary_row d-flex justify-content-between align-items-center mb-2">
                    <label className="custom-radio">Sub Total</label>
                    <div>
                      <p>${total.toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="total_payable d-flex justify-content-between align-items-center">
                    <h3>Total Payable</h3>
                    <h3>${total.toFixed(2)}</h3>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Payment Methods */}
            <div className="col-lg-6">
              <div className="billing_box_wrap px-0">
                <h5 className="px-5">Online banking</h5>
                <div className="payment_modes_wrap">
                  <div className="payment_mode_row_wrap d-flex justify-content-between align-items-center">
                    <div className="radio-container">
                      <label className="custom-radio">
                        <input type="radio" name="radio-group" />
                        <span className="radio-checkmark"></span>
                        PayPal
                      </label>
                    </div>
                    <img src={PaypalLogo} alt="PayhandleSubmitPal logo" />
                  </div>
                  <div className="payment_mode_row_wrap d-flex justify-content-between align-items-center">
                    <div className="radio-container">
                      <label className="custom-radio">
                        <input type="radio" name="radio-group" />
                        <span className="radio-checkmark"></span>
                        PicPay
                      </label>
                    </div>
                    <img src={PicPay} alt="PicPay logo" />
                  </div>
                  <div className="payment_mode_row_wrap d-flex justify-content-between align-items-center">
                    <div className="radio-container">
                      <label className="custom-radio">
                        <input type="radio" name="radio-group" />
                        <span className="radio-checkmark"></span>
                        Apple Pay
                      </label>
                    </div>
                    <img src={ApplePay} alt="Apple Pay logo" />
                  </div>
                  <div className="payment_mode_row_wrap d-flex justify-content-between align-items-center px-4 mt-4 mb-4">
                    <div className="radio-container">
                      <label className="custom-radio">
                        <input type="radio" name="radio-group" />
                        <span className="radio-checkmark"></span>
                        Credit/Debit card
                      </label>
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      <img src={MasterCard} alt="MasterCard logo" />
                      <img src={ViSa} alt="Visa logo" />
                    </div>
                  </div>
                </div>

                {/* Credit/Debit Card Payment Form */}
                <div className="form_for_CC_DC">

                <div className='mt-4'>
						<div id='payment-element' ref={paymentElementRef}></div>
					</div>

                
                      {!clientSecret?  <div className="pay_now_btn_wrap">
                          <button type="submit"  onClick={handleSubmit}>
                            Make payment
                          </button>
                        </div>:
                        <div className="pay_now_btn_wrap">
                          <button type="submit"  onClick={handlePaymentConfirmation}>
                            Pay Now
                          </button>
                        </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;


