import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {  createSchoolApi } from "../api/user";

const initialState = {
  createUser: null,
  createUserLoading: false,
  createUserError: null,


  School: {
    seriesId: '',
    Name: '',
    cnpj: '',
    aboutSchool: '',
    established_date: "",
    city: '',
    state: '',
    country: '',
    Location: '',
    contact_persons: [
      {
        name: '',
        email: '',
        position: '',
        number: '',
        gender:''
      }
    ]
  }



};





// createSchoolApi-------//


export const creatShoolSlice = createAsyncThunk(
  'school/createSchool',
  async (params, { rejectWithValue }) => {
    //console.log("params params", params)


    try {
      const response = await createSchoolApi(params);
      return response;
    } catch (error) {
      // Ensure that the error response is passed correctly
      return rejectWithValue(error.data || { message: 'An unknown error occurred' });
    }
  }
);




const SchoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    changeData: (state, action) => {
      const { name, value } = action.payload;
      // //console.log({ name, value }, 'new');
      state.School[name] = value;
    }


  },
  extraReducers: (builder) => {
    builder
      .addCase(creatShoolSlice.pending, (state) => {
        state.createUserLoading = true;
        state.createUserError = null;
      })
      .addCase(creatShoolSlice.fulfilled, (state, action) => {
        // //console.log("Home action ", action)
        state.createUser = action.payload.data;
        state.createUserLoading = false;
        state.createUserError = null;
      })
      .addCase(creatShoolSlice.rejected, (state, action) => {
        state.createUserError = action.payload;
        state.createUserLoading = false;
      })

  }
});

export const { changeData } = SchoolSlice.actions
export default SchoolSlice.reducer;
