import axios from "axios";
import { BASE_URL } from "../store/baseUrl";
 

export const fetch_friend_request = async (data) => {
    const {userID}=data
    try {
      const resoponse = await axios.get(
        `${BASE_URL}friend-request/getFriendRequests/${userID}`);
      return resoponse?.data;
    } catch (error) {
      throw error;
    }
  };

  export const fetch_getFriends = async (data) => {
    const {userID}=data
    try {
      const resoponse = await axios.get(
        `${BASE_URL}friends/getFriends/${userID}`);
      return resoponse?.data;
    } catch (error) {
      throw error;
    }
  };

  export const fetch_friend_Chat= async (data) => {
    const {RoomID}=data
    try { 
      const resoponse = await axios.get(
        `${BASE_URL}chat/getchats/${RoomID}`);
      return resoponse?.data;
    } catch (error) {
      throw error;
    }
  };



  export const fetch_remove_chat= async (data) => {
    const {RoomID}=data
    try { 
      const resoponse = await axios.delete(
        `${BASE_URL}chat/removeChat/${RoomID}`);
      return resoponse?.data;
    } catch (error) {
      throw error;
    }
  };


  export const remove_message= async (data) => {
    const {msgId}=data
    try { 
      const resoponse = await axios.delete(
        `${BASE_URL}chat/removeMsg/${msgId}`);
      return resoponse?.data;
    } catch (error) {
      throw error;
    }
  };